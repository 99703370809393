
import React from "react";
import CommonUtils from '../http/Common';
import { Descriptions, Divider, Button, Checkbox, Form, Input, Radio, Space, message, } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

class Agreement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null
        };
    }

    componentDidMount() {
        let _this = this;
        CommonUtils.goToTop()//回到顶部


    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }


    render() {
        const { } = this.state;

        var contents = [
            { bold: 1, text: '【首部及导言】' },
            { bold: 0, text: '' },
            { bold: 0, text: '欢迎您使用爆单ERP系列会员服务！' },
            { bold: 0, text: '' },
            { bold: 0, text: '为使用爆单ERP系列会员服务（简称为：本服务），请您仔细阅读、理解并遵守《爆单ERP系列会员服务协议》。' },
            { bold: 0, text: '' },
            { bold: 1, text: '本协议由您与深圳契富智能科技有限公司（简称为：契富智能）共同缔结，具有合同效力。契富智能提醒您，请重点并仔细阅读本协议中以黑体加粗、下划线、颜色标记或以其他合理方式提示您注意的条款。该等条款可能与您的权益有重大利害关系，包括但不限于相关约定契富智能责任的条款、约定双方权利义务的条款、约定争议解决方式和司法管辖的条款等。如果您认为该等条款可能导致您在特定情况下部分或全部利益或权利受损，请您在确认同意本协议之前或在使用本服务之前务必再次阅读前述条款，并在您自愿接受该等条款的前提下使用本服务。' },
            { bold: 0, text: '' },
            { bold: 1, text: '如果您选择爆单ERP系列会员服务，您可以通过接受本服务的形式（包括但不限于点击同意、接受或下一步、进入购买程序、对爆单ERP服务进行任何购买/赠送、接受赠予、参加活动获得爆单ERP服务使用资格、进行或参与任何与本服务相关的交易或活动、上传服务、采用任何其他形式的确认操作等，下统称：接受服务的形式）表明您已阅读、理解并同意签署本协议，表明您与契富智能已达成协议关系，并自愿接受并遵守本协议的全部约定。' },
            { bold: 0, text: '' },
            { bold: 0, text: '如果您未满18周岁，应在法定监护人的指导和陪同下阅读并确认本协议条款。如您为未成年人法定监护人，希望您合理设定未成年人操作时间，培养未成年人健康的习惯。' },
            { bold: 0, text: '' },
            { bold: 0, text: ' ' },
            { bold: 0, text: '' },
            { bold: 1, text: '一、【协议的定义及说明】' },
            { bold: 0, text: '' },
            { bold: 1, text: '1.1【爆单ERP系列会员及账号】' },
            { bold: 0, text: '' },
            { bold: 0, text: '1.1.1爆单ERP系列会员包括：爆单ERP  VIP会员、爆单ERP超级VIP会员（合称为：“爆单ERP系列会员”）。如无特别说明，本协议项下爆单ERP系列会员统称“您”、“用户”或“会员”。契富智能有权根据实际需要不时调整会员类别、名称、等级等，并将以适当的方式通知您。' },
            { bold: 0, text: '' },
            { bold: 0, text: '爆单ERP VIP会员：可在windows电脑端使用；' },
            { bold: 0, text: '' },
            { bold: 0, text: '1.1.2您如果需要使用和享受爆单ERP系列会员服务，则您需要将您享有使用权的QQ账号或微信账号在爆单ERP电脑软件、网页、H5、小程序等应用（统称“爆单ERP应用”）上授权登录（不同类别会员可在不同应用享受权益不同），授权登录后，爆单ERP将根据该授权的QQ账号或微信账号生成对应的爆单ERP账号，您可以为该爆单ERP账号充值会员时长。' },
            { bold: 0, text: '' },
            { bold: 0, text: '充值成功后，该爆单ERP账号在会员时长有效期内即为对应的“爆单ERP VIP会员账号”或“爆单ERP超级VIP会员账号”（合称为：“爆单ERP系列会员账号”），且在会员时长有效期内享受相应的爆单ERP系列会员服务。如无特别说明，本协议关于爆单ERP系列会员的约定同时适用于爆单ERP VIP会员与爆单ERP超级VIP会员。' },
            { bold: 0, text: '' },
            { bold: 0, text: '1.1.3 在此需要特别提醒您，爆单ERP系列会员与契富智能其他应用（包括但不限于契富智能AI妮）会员属于不同的会员体系，您成为爆单ERP系列会员，并不意味着您可以同时获得契富智能其他应用的会员资格。如您需要开通其他会员服务，请您阅读相关会员服务页面的规则或服务协议，并根据需求选择开通相关会员服务。' },
            { bold: 0, text: '' },
            { bold: 1, text: '1.2【本协议】' },
            { bold: 0, text: '本协议服务同时包括契富智能已经或在未来不时发布的关于本服务的相关协议、业务规则、附加条款、产品要求（包括但不限于年龄要求）等服务。上述服务一经正式发布即生效，为本协议不可分割的组成部分。' },
            { bold: 0, text: '' },
            { bold: 1, text: '1.3【爆单ERP系列会员服务（又简称为：本服务）】' },
            { bold: 0, text: '' },
            { bold: 0, text: '爆单ERP系列会员服务，即爆单ERP系列会员可在爆单ERP各应用内获取的爆单ERP系列会员服务及契富智能不时特别赠送的服务。您理解并同意，爆单ERP特别赠送的服务为爆单ERP系列会员服务以外的额外福利，其提供方式、服务、期限及范围均由契富智能决定，契富智能有权进行调整。' },
            { bold: 0, text: '' },
            { bold: 0, text: '' },
            { bold: 0, text: '' },
            { bold: 0, text: '请您理解，爆单ERP系列会员类别多样，不同类别的会员权益存在差异；同一类型的会员分为不同等级，不同等级之间的会员权益亦可能存在差异。同时，本服务具体服务及可以适用的终端将根据您的爆单ERP系列会员类别和契富智能所获得的授权（为提供本服务向第三方获取的授权，包括但不限于版权方、营销奖品提供方等）而有所不同。' },
            { bold: 0, text: '' },
            { bold: 0, text: '' },
            { bold: 0, text: '请您特别关注，您可能无法免费使用所有爆单ERP服务，部分服务需要额外付费，具体以页面提示为准。' },
            { bold: 0, text: '' },
            { bold: 1, text: '1.4【爆单ERP系列会员规则】' },
            { bold: 0, text: '' },
            { bold: 0, text: '契富智能会不时发布并修订关于爆单ERP的用户守则、活动规则、使用规则、公告、提示、通知等服务。契富智能将通过系统提示和或信息推送或后台公告等形式发布，上述服务一经正式发布即生效，为本协议不可分割的组成部分。' },
            { bold: 0, text: '' },
            { bold: 1, text: '1.5【爆单ERP系列会员服务提供方】' },
            { bold: 0, text: '' },
            { bold: 0, text: '爆单ERP系列会员服务提供方是指深圳契富智能科技有限公司，在本协议中简称为“契富智能”。' },
            { bold: 0, text: '' },
            { bold: 1, text: '1.6【用户数据】' },
            { bold: 0, text: '' },
            { bold: 0, text: '用户数据是指您在使用本服务过程中产生的被服务器记录的各种数据，包括但不限于登录记录、浏览记录、使用软件记录、下载缓存记录、上传文件（包括但不限于文件、软件等）记录、虚拟物品数据、操作行为日志、购买日志、充值记录、购买记录、支付记录、打赏、积分兑换、商品购买记录、留言、评论、会员等级、会员期限、会员资产等数据。' },
            { bold: 0, text: '' },
            { bold: 0, text: '根据相关法律法规及国家标准的规定，部分用户数据属于个人信息。如果您需要了解我们如何收集、处理、保护您的个人信息，请联系客服。' },
            { bold: 0, text: '' },
            { bold: 1, text: '1.7【协议修改】' },
            { bold: 0, text: '' },
            { bold: 0, text: '契富智能可能会出于业务需要、版权方要求、版权采购价格的变化、法律法规要求、主管部门通知等，对本协议（包括但不限于会员类别、会员名称、相关权益细则、收费标准(包括但不限于会员套餐、自动续费套餐、额外付费等服务价格、方式等）进行修改（修改包含变更、增加、减少相应的条款服务）。一旦本协议发生修改，契富智能将通过系统提示和/或信息推送和/或后台公告等形式发布，请您务必仔细阅读。如您选择继续使用本服务，即表示您充分阅读、理解并同意受经修订的本协议约束。如您对本协议的修改有任何问题或不同意我们对本协议的修改，可以停止使用本服务并咨询客服。但需要提醒您的是，更新后的本协议自更新版本发布之日起生效。' },
            { bold: 0, text: '' },
            { bold: 1, text: '1.8【使用原则】' },
            { bold: 0, text: '' },
            { bold: 0, text: '您在使用爆单ERP系列会员服务过程中，应遵守法律法规，包括但不限于《中华人民共和国民法典》、《中华人民共和国著作权法》、《中华人民共和国网络安全法》、《中华人民共和国个人信息保护法》《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《信息网络传播权保护条例》等有关法律、法规；应遵守公共秩序，尊重公序良俗，不得危害网络安全，不得利用网络从事危害国家安全、荣誉和利益，煽动颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一，宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视，传播暴力、淫秽色情信息，编造、传播虚假信息扰乱经济秩序和社会秩序，以及侵害他人名誉、隐私、知识产权和其他合法权益等活动。在任何情况下，契富智能一旦合理地认为用户存在上述行为的，出于维护网络安全、公共秩序等必要性，可以在任何时候，不经事先通知，终止向该用户提供服务。' },
            { bold: 0, text: '' },
            { bold: 1, text: '1.9【其他】' },
            { bold: 0, text: '' },
            { bold: 0, text: '如您在爆单ERP上看到爆单ERP系列会员服务以外的其他付费服务（包括但不限于契富智能AI妮VIP系列会员服务等），并且想了解相关服务，请您仔细阅读相关服务页面的规则或服务协议，并请关注服务提供方；如您需要开通该类服务，将由对应服务提供方向您提供服务并收取费用，后续对于该服务有任何问题，请您联系服务提供方。' },
            { bold: 0, text: '' },
            { bold: 0, text: '' },
            { bold: 1, text: '二、【爆单ERP系列会员账号管理及使用】' },
            { bold: 0, text: '' },
            { bold: 1, text: '2.1【登录及注销】' },
            { bold: 0, text: '' },
            { bold: 0, text: '您在一台设备上授权登录您的爆单ERP系列会员账号后，该设备上的爆单ERP应用在登录有效期内将一直以您的爆单ERP系列会员账号为对象提供服务，如果您不想保留相关数据记录或想使用其他设备享用爆单ERP系列会员服务，请您在使用后选择退出登录。' },
            { bold: 0, text: '' },
            { bold: 0, text: '如您需要注销爆单ERP系列会员账号，则需要注销爆单ERP账号，请联系客服。' },
            { bold: 0, text: '' },
            { bold: 0, text: '登录后，您可以查看开通的服务期限、会员类别等用户数据。' },
            { bold: 0, text: '' },
            { bold: 1, text: '2.2 【账号管理】' },
            { bold: 0, text: '' },
            { bold: 1, text: '2.2.1请您正确使用及妥善保管、维护您的爆单ERP系列会员账号及密码，如发生任何泄漏、遗失、被盗等行为，而该等行为并非契富智能法定过错导致，所有损失将由您自行承担；' },
            { bold: 0, text: '' },
            { bold: 0, text: '2.2.2出于维护您账号安全之考虑，您在此授予契富智能保护您账号安全的相关权利，契富智能可以（但无义务）定期或不定期采用不同的方式对您账号的使用安全进行检查、验证，包括但不限于主动联系您进行身份验证、短消息验证、邮箱认证、人脸识别认证等。如您无法完成验证或无正当理由拒绝验证的，契富智能会合理怀疑您的账号出现异常或被盗。出于账号安全保护之必要性，契富智能可能会中止向该账号提供服务及/或采取进一步措施。' },
            { bold: 1, text: '2.3【使用规则】' },
            { bold: 0, text: '' },
            { bold:1, text: '2.3.1【合法获取及使用】' },
            { bold: 0, text: '' },
            { bold: 1, text: '（1）爆单ERP系列会员服务，均仅限于您在爆单ERP官方运营或授权的程序内使用。爆单ERP以上会员服务适用范围随着合作拓展和变化不断增加和调整，以您享受会员服务时的实际情况为准。任何以破解、转译等非法手段将爆单ERP系列会员服务与爆单ERP官方指定的程序分离的行为，均不属于本协议中约定的爆单ERP系列会员服务。契富智能保留追究侵权行为人的法律责任及索赔的权利。' },
            { bold: 0, text: '' },
            { bold: 1, text: '（2）任何以盗窃、利用系统漏洞、通过任何非契富智能官方或授权途径获得的爆单ERP系列会员服务（包括但不限于购买、租用、借用、分享、受让等方式获得）、恶意利用或破坏爆单ERP系列会员活动获得的会员服务等行为，均为不合法行为，不获本协议项下的相关保护，契富智能有权取消及/或不予提供会员服务，所有产生的损失及责任由侵权行为人自行承担。' },
            { bold: 0, text: '' },
            { bold: 1, text: '（3）使用爆单ERP系列会员服务及/或参加相关活动时，您应通过爆单ERP官方公布/授权的方式进行，用户通过其他任何渠道、任何途径、任何方式非法获取的爆单ERP系列会员服务（包括且不限于账号、积分、积分商品等）或参加活动获取福利（包括但不限于获得实体/虚拟礼品、会员服务、会员权益、会员服务兑换码等），均为不合法行为，不获本协议项下的相关保护。且一经发现，契富智能有权立即就相关服务做出删除、取消、清零等处理，或采取其他如中止或终止对该用户提供服务等账号管理措施，所有因此产生的损失及责任由该用户自行承担。' },
            { bold: 0, text: '' },
            { bold: 0, text: '（4）您参加爆单ERP系列会员活动时，应按照活动规则、以合法及不损害活动举办方利益的方式进行。如有用户利用系统漏洞、规则设置缺陷、系统设置错误、滥用会员身份等方式参加活动并获取相关福利的，均为不合法行为，不获本协议项下的相关保护，且契富智能有权采取相关措施进行处理，所有因此产生的损失及责任由侵权行为人自行承担。' },
            { bold: 0, text: '' },
            { bold: 1, text: '（5）您在使用本服务时应当遵守国家法律、法规、政策规定以及契富智能发布的使用规则，包括但不限于打赏和充值限制和要求等。' },
            { bold: 0, text: '' },
            { bold: 1, text: '2.3.2【专有及非商业目的使用】' },
            { bold: 0, text: '' },
            { bold: 0, text: '（1）爆单ERP系列会员服务，是在您遵守本协议及相关法律、法规、政策、公序良俗等的前提下，契富智能给予您一项个人的、不可转让的、非商业用途的、可撤销的、有期限及非排他性的服务。' },
            { bold: 0, text: '' },
            { bold: 0, text: '（2）您理解并同意，您不得将享有爆单ERP系列会员服务的账号以任何方式提供给他人使用，包括但不限于不得以转让、出租、借用、分享、出售等方式提供给他人作包括但不限于直播、录制、使用、上传、参加活动等使用。否则，因此产生的任何法律后果及责任均由您自行承担，且契富智能有权中止或终止对您提供服务。' },
            { bold: 0, text: '' },
            { bold: 0, text: '' },
            { bold: 1, text: '2.3.5【权利归属】' },
            { bold: 0, text: '' },
            { bold: 0, text: '您仅拥有合法获得的爆单ERP系列会员账号下积分、虚拟积分商品、爆单ERP系列会员服务、虚拟社区商品等服务和产品的使用权，上述服务和产品及其衍生物的所有权及知识产权均归契富智能所有，但是您经合法渠道取得的实体产品所有权或契富智能另有公告说明的除外。契富智能将根据实际情况决定服务、产品及衍生物的使用期限及/或终止期限并相应公告，届时请您仔细阅读。' },
            { bold: 0, text: '' },
            { bold: 0, text: '服务和产品产生的数据归属于契富智能所有，均在您亲自操作下授权进行。' },
            { bold: 0, text: '' },
            { bold: 1, text: '2.3.6【登录限制】' },
            { bold: 0, text: '' },
            { bold: 0, text: '您理解并同意，在开通本服务后：' },
            { bold: 0, text: '' },
            { bold: 0, text: '（1）如您是爆单ERP VIP会员，同一个账号最多可以在五个设备（“设备”指包括但不限于手机端、电脑端、平板电脑端、网页端和电视端等终端设备）登录及使用（“使用”指包括但不限于登录、使用会员服务等），如您是爆单ERP超级VIP会员，同一个账号则最多可以在八个设备登录及使用；' },
            { bold: 0, text: '' },
            { bold: 0, text: '（2）如您是爆单ERP VIP会员，同一时间内同一账号最多在两个设备上使用，如您是爆单ERP超级VIP会员，则同一时间内同一账号最多可在三个设备上使用；' },
            { bold: 0, text: '' },
            { bold: 0, text: '超过上述范围使用的，契富智能将会根据情况中止或终止对您提供的服务，且契富智能保留采取进一步措施的权利，包括但不限于追偿损失等。' },
            { bold: 0, text: '' },
            { bold: 1, text: '2.4【免费模式】' },
            { bold: 0, text: '' },
            { bold: 0, text: '2.4.1您进一步知悉并同意，您不使用爆单ERP账号进行登录，您将以游客模式使用爆单ERP，亦可进行软件应用部分业务的体验，但在游客模式下可能无法进行充值或消费。且一旦您卸载或重装爆单ERP，或您更换手机、电脑等终端设备或该终端设备损坏的，您在该游客模式下所有用户数据可能都将会被清空，且无法查询和恢复。如因此造成您任何损失的，需要您自行承担。' },
            { bold: 0, text: '' },
            { bold: 0, text: '' },
            { bold: 1, text: '2.6【账号被冒用、被盗用及遗失】' },
            { bold: 0, text: '' },
            { bold: 0, text: '2.6.1如发生您的爆单ERP系列会员账号及密码被他人冒用或盗用、或任何其他未经您合法授权使用的情形时：' },
            { bold: 0, text: '' },
            { bold: 0, text: '（1）您应立即以契富智能要求的有效方式通知契富智能并告知契富智能需采取的措施;' },
            { bold: 0, text: '' },
            { bold: 0, text: '通知方式：Web端：https://erp.aini996.com/index.html  添加客服微信，联系客服进行反应。' },
            { bold: 0, text: '' },
            { bold: 0, text: '' },
            { bold: 0, text: '（2）您通知契富智能时，应提供与您注册身份信息相一致的个人有效身份信息及/或契富智能要求的相关证明、验证信息;' },
            { bold: 0, text: '' },
            { bold: 0, text: '（3）契富智能收到您的有效请求并核实身份后，契富智能会根据您的要求或结合具体情况采取相应账号管理措施；' },
            { bold: 0, text: '' },
            { bold: 1, text: '（4）契富智能因根据您的请求采取相应措施，如有此造成您及/或其他用户损失的，请理解，需要由您自行承担；' },
            { bold: 0, text: '' },
            { bold: 1, text: '（5）若您没有提供有效身份信息或您提供的个人有效身份信息与所注册的身份信息不一致的，或不能提供相关证明、验证信息或错误提供的，契富智能将拒绝您的请求，因此造成您损失的，需要由您自行承担。' },
            { bold: 0, text: '' },
            { bold: 1, text: '2.6.2您爆单ERP系列会员账号关联使用的QQ、微信账号信息遗失、忘记密码，导致无法使用爆单ERP系列会员服务的，您如需找回，需按照契富智能官方公布的账号找回流程的要求提供相应的信息和证明，并确保提供的信息和证明文件的合法真实有效。若您提供的材料不真实或不符合要求，则无法通过契富智能的安全验证，可能会导致账号找回失败，相应的风险和损失将由您自行承担。' },
            { bold: 0, text: '' },
            { bold: 1, text: '2.6.3因契富智能对您的请求采取行动及处理均需要合理时间，如您发现被冒用、被盗用及遗失时，请尽快进行前述程序。除契富智能存在法定过错外，契富智能对您的损失不承担任何责任。' },
            { bold: 0, text: '' },
            { bold: 0, text: ' ' },
            { bold: 1, text: '【设备及系统差异】' },
            { bold: 0, text: '' },
            { bold: 0, text: '您通过契富智能指定渠道开通本服务后，可能会由于您使用的软件版本、设备、操作系统等不同或其他第三方原因等导致您实际可使用的具体服务有所差别，由此可能给您带来的不便，您表示理解并同意豁免契富智能的相关责任。' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.6【广告服务】' },
            { bold: 0, text: '' },
            { bold: 0, text: '3.6.1您理解并同意，您在使用本服务的过程中，将可能会接触到以各种方式投放的不同类型的广告或其他类型的商业信息（包括在您上传、发布或传输的服务上投放广告等）。' },
            { bold: 0, text: '' },
            { bold: 0, text: '广告的类型包括但不限于通栏广告、弹出广告、按钮广告、浮动广告、片头广告、创意中插广告、跑马灯广告、片尾广告、植入广告、弹窗广告、暂停广告等。' },
            { bold: 0, text: '' },
            { bold: 0, text: '3.6.2 契富智能将会根据实际情况，为不同类别的爆单ERP系列会员不同程度地减免其在使用节目服务时看到的广告时长，但并不会完全消除所有广告和商业信息。' },
            { bold: 0, text: '' },
            { bold: 0, text: '3.6.3您理解并同意，契富智能可以通过电子邮件、站内信息、手机短信、微信、网站公告或其他方式向您发送推荐的影视服务、会员福利、优惠活动等信息。您可通过隐私设置关闭相关推送，也可以不提供相关通知途径。但在您授权进行通知后，我们将会及时告知您相关变更及福利推送。' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.7【关于收费】' },
            { bold: 0, text: '' },
            { bold: 0, text: '本服务是契富智能提供的收费服务，您须在按照本服务的收费标准支付相应费用后，方可使用本服务。此外，您可能也可以通过接受好友赠送、使用限时广告解锁、单集/全集付费等合法及契富智能认可的其他方式享有、使用本服务。' },
            { bold: 0, text: '' },
            { bold: 0, text: '契富智能会根据实际状况给予不同类别、不同等级的会员不同的购买、开通、续费优惠，具体优惠政策请阅读契富智能在相关服务页面发布的信息。因参加活动、会员类别或会员等级不同，爆单ERP系列会员将享受不同的特色服务、资费或福利赠送。' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.8【支付】' },
            { bold: 0, text: '' },
            { bold: 0, text: '3.8.1您应该通过契富智能指定的现有方式包括但不限于微信支付等或今后契富智能指定的方式支付费用。不同终端和系统可支持的支付方式可能不同，请根据支付页面的指示完成支付。支付完成后，除法定情形外，不可进行转让或要求退费。' },
            { bold: 0, text: '' },
            { bold: 0, text: '3.8.2您不得通过以下任何方式为自己或他人开通本服务：' },
            { bold: 0, text: '' },
            { bold: 0, text: '（1）以营利、经营等非个人使用的目的为自己或他人开通本服务；' },
            { bold: 0, text: '' },
            { bold: 0, text: '（2）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等程序、软件方式为自己或他人开通本服务；' },
            { bold: 0, text: '' },
            { bold: 0, text: '（3）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；' },
            { bold: 0, text: '' },
            { bold: 0, text: '（4）通过非契富智能指定的方式为自己或他人开通本服务；' },
            { bold: 0, text: '' },
            { bold: 0, text: '（5）通过侵犯契富智能或他人合法权益的方式为自己或他人开通本服务；' },
            { bold: 0, text: '' },
            { bold: 0, text: '（6）通过其他违反本协议或相关法律、行政法规、国家政策等方式为自己或他人开通本服务。' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.9【服务期限】' },
            { bold: 0, text: '' },
            { bold: 0, text: '3.9.1本服务的期限以您自行选择并成功支付相应服务费用的期限为准，您可以登录契富智能充值中心或者本服务的相应页面查询。该期限不因您未使用或其他行为而有所延长。会员服务到期未进行续费，则我们将停止对您的服务。如您使用自动续费服务，则每一个计费期到期扣费成功后，自动帮您顺延相应的服务期限；如果扣费不成功，则视同会员服务到期并停止服务。' },
            { bold: 0, text: '' },
            { bold: 0, text: '3.9.2本服务的开通最高期限会受到一定限制（目前上限为2099年12月31日），具体以相关服务页面公布为准。' },
            { bold: 0, text: '' },
            { bold: 0, text: '3.9.3请您理解，在本服务的服务期间，由于互联网服务的特殊性，可能会出现非因契富智能故意行为而导致的可能影响您正常使用服务的情形（包括但不限于契富智能解决故障、服务器维修、调整、升级、对第三方侵权进行处理等），契富智能将尽可能降低该等情形对您的影响。您理解并同意，契富智能无需对上述情形产生的不利后果承担责任，并不要求契富智能另行补偿。' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.10【使用准则】' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.10.1任何用户或第三方不得利用本服务进行任何侵犯契富智能以及任何第三方的知识产权、财产权、名誉权等合法权益的行为；' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.10.2本服务是一项收费服务，以任何手段侵犯、破坏契富智能收取费用的行为，契富智能保有追究行为人责任及索取赔偿的权利；' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.10.3任何用户或第三方不得利用本服务进行任何危害或涉嫌危害未成年人的行为；' },
            { bold: 0, text: '' },
            { bold:1, text: '3.10.4任何用户或第三方不得利用本服务从事任何违反法律法规、政策或公序良俗、社会公德等的行为；' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.10.5您充分了解并同意，您必须为自己登录账号下的一切行为负责，包括但不限于您所发表的任何服务以及由此产生的任何后果。您应对本服务中的服务自行加以判断，并承担因使用服务而引起的所有风险，包括因对服务的正确性、完整性或实用性的依赖而产生的风险。' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.11【关于爆单ERP系列会员服务的特别提示】' },
            { bold: 0, text: '' },
            { bold: 1, text: '您理解并同意，由于您使用的设备终端的产品技术及第三方授权不同，爆单ERP系列会员服务可能在不同终端有所差异，具体请见契富智能的页面说明或该终端可以支持的服务说明。' },
            { bold: 0, text: '' },
            { bold: 1, text: '契富智能有权根据法律规定及政策变更、版权方要求、运营需求、用户需求等进行爆单ERP系列会员服务的全部或部分调整（包括但不限于变更、取消、减少、增加（软件服务播出进度、停播或额外付费点播、付费限制等）），就前述服务的调整，契富智能将在会员服务页面、相应具体活动页面或以其他合理方式进行发布，并在发布后生效，我们建议请您仔细阅读。' },
            { bold: 0, text: '' },
            { bold: 1, text: '若您需对于爆单ERP系列会员服务的调整进一步咨询或反馈建议，您可通过“爆单ERP软件-个人中心-投诉建议”提交建议或联系契富智能客服。' },
            { bold: 0, text: '' },
            { bold: 1, text: '3.12【爆单ERP系列会员额外付费服务】' },
            { bold: 0, text: '' },
            { bold: 0, text: '3.12.1您理解并同意，您成功开通爆单ERP系列会员服务后，在您享有的会员服务之外，仍有少量软件服务需要您额外付费成功后方可使用，包括但不限于关注大卖粉丝，图片翻译等，您可以自愿选择额外付费后使用契富智能所提供的额外付费服务，额外付费的价格、优惠幅度可能会因不同类别、不同等级的会员有所差异。会员额外付费包括如下情形：' },
            { bold: 0, text: '' },
            { bold: 0, text: '（1）可使用使用券使用的服务：爆单ERP上有部分影片需要用使用券使用。如您有使用券，您可使用使用券进行使用而无需额外付费，但当您的使用券无剩余张数时，您需额外付费购买使用券或额外付费点播该影片（如适用）；' },
            { bold: 0, text: '' },
            { bold: 0, text: '（2）不可使用使用券或其他类型抵用券的付费软件服务：该类服务均需在您额外付费成功后方可使用，不支持使用使用券或其他类型的抵用券。' },
            { bold: 0, text: '' },
            { bold: 0, text: '请您理解，契富智能亦有权根据实际情况决定将额外付费的软件服务提供给非爆单ERP系列会员的用户通过付费购买、使用广告时长等任务解锁及契富智能认可的其他方式享有。此外，在非爆单ERP系列会员情形下，如符合契富智能不时公布的使用条件，包括但不限于满足一定的使用广告时长、参与特定的活动等。' },
            { bold: 0, text: '' },
            { bold: 0, text: '' },
            { bold: 1, text: '四、【会员个人信息收集、使用及保护】' },
            { bold: 0, text: '' },
            { bold: 0, text: '4.1' },
            { bold: 0, text: '' },
            { bold: 1, text: '您充分理解并同意：契富智能在遵守合法、正当、必要和诚信原则下，有权为履行本协议之目的收集您的信息，这些信息包括您的各跨境电商平台的账号、各ERP平台的账号、各购物网站的账号、通过手机验证码形成的爆单ERP账号、账号关联的QQ邮箱、昵称、头像，您账号下的用户数据以及其他您在使用爆单ERP服务的过程中主动填写或向契富智能提供的手机号码、收货地址等信息或基于使用爆单ERP产生的成长值等相关信息，或契富智能基于账号安全、用户体验优化等考虑而需收集的信息，契富智能对您的信息的收集将遵循本协议、相关隐私政策及《中华人民共和国个人信息保护法》等相关法律法规及国家标准的规定。' },
            { bold: 0, text: '' },
            { bold: 1, text: '为参加各种会员活动或获得契富智能某项特定服务，您可能需要提供您的姓名、国籍、性别、学历学籍、职业、住址、联系方式等，请您注意页面提示信息及选择是否参加相关活动或获得特定服务。如您选择提供前述信息，契富智能亦会收集上述信息。' },
            { bold: 0, text: '' },
            { bold: 0, text: '' },
            { bold: 0, text: '4.2' },
            { bold: 0, text: '' },
            { bold: 1, text: '您应对通过爆单ERP及相关服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，您不应搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。' },
            { bold: 0, text: '' },
            { bold: 0, text: '' },
            { bold: 1, text: '五、【违约责任】' },
            { bold: 0, text: '' },
            { bold: 0, text: '5.1' },
            { bold: 0, text: '' },
            { bold: 1, text: '您在使用本服务的过程中，如果出现以下行为，将会被认定为违约，请特别注意：' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.1.1违反本协议约定的；' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.1.2违反法律、法规、规章、条例以及任何具有法律效力之规范规定的；' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.1.3破坏、影响契富智能对任何第三方提供本服务的；' },
            { bold: 0, text: '' },
            { bold: 0, text: '5.1.4' },
            { bold: 0, text: '' },
            { bold: 1, text: '进行危害计算机网络安全的行为；' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.1.5对契富智能及关联第三方（包括授权方和合作方）提供的本协议服务、活动造成不良影响，侵犯及/或关联第三方及/或其他用户的正当利益的；' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.1.6被有关部门认定侵犯任何第三方的合法权益的；' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.1.7利用本服务获取非法利益的，包括但不限于通过转售、转让、转授权等行为不正当牟利；' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.1.8其他侵犯契富智能合法利益的行为。' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.2【违约行为处理】' },
            { bold: 0, text: '' },
            { bold: 1, text: '契富智能发现违约行为后，有权采取以下一项或多项措施处理：' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.2.1采取技术手段予以删除、屏蔽或断开相关的信息；' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.2.2采取包括但不限于中止或终止部分或全部本服务；' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.2.3如果由于您存在的违约行为导致本服务被中止或终止，契富智能无需向您退还任何费用，您账号内的损失（包括但不限于用户数据及虚拟产品的减少、灭失等）由您自行承担，造成契富智能损失的（包括但不限于律师费、诉讼费等），您也应予以赔偿。' },
            { bold: 0, text: '' },
            { bold: 1, text: '5.3【赔偿责任】' },
            { bold: 0, text: '' },
            { bold: 1, text: '如用户的违约行为使契富智能及其关联公司遭受损失，包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失，用户应当赔偿契富智能及其关联公司的上述全部损失。' },
            { bold: 0, text: '' },
            { bold: 1, text: '如果因为您的违约行为导致任何第三方损害的，您应当独立承担责任。如因任何原因导致契富智能先行向第三方赔偿，您应该在收到契富智能的通知后立即将赔偿金支付给契富智能。' },
            { bold: 0, text: '' },
            { bold: 0, text: ' ' },
            { bold: 0, text: '' },
            { bold: 1, text: '六、【重要声明】' },
            { bold: 0, text: '' },
            { bold: 1, text: '6.1 您知悉并同意，用户在爆单ERP上上传、发布或传输的服务，为用户的个人行为，契富智能不对该等信息和服务的准确性、真实性、可用性、安全性、完整性和正当性承担责任，亦无须承担任何责任。' },
            { bold: 0, text: '' },
            { bold: 1, text: '6.2您如果接触到违法、违规、使人感到不适不快甚至产生恐惧、厌恶情绪的服务，请您及时联系契富智能进行处理，非常感谢您的协助。' },
            { bold: 0, text: '' },
            { bold: 1, text: '6.3 您在进行相关信息及服务的使用、使用、分享、借鉴、转载、转发、转述等行为（统称为“使用行为”）时，应进行独立判断并合法使用，如果因您的使用行为造成任何自身损害及对第三方的损害，契富智能不承担任何责任。' },
            { bold: 0, text: '' },
            { bold: 1, text: '6.4因电信和网通部门的通讯线路故障、网络或电脑故障、系统不稳定以及不可抗力等非契富智能主观原因造成您账号、账号内财产等丢失、减少的，契富智能不承担任何责任。' },
            { bold: 0, text: '' },
            { bold: 1, text: '6.5出于满足合法合规要求之必要性或因不可抗力事由，契富智能可能会修改、变更、中断或终止部分或全部服务，变更、删除、转移用户存储、发布在爆单ERP的服务（统称“变更行为”），在该情形下，契富智能将尽可能事先通知您前述变更行为。但基于互联网软件服务之特性，部分变更行为无法向您通知或无法向您提前通知（包括但不限于：软件服务因存在违法、违规、违反政策的服务而需要删除、变更等）。您理解，契富智能无需对您或对任何第三人承担任何责任。' },
            { bold: 1, text: '6.6 您使用本服务前，请熟悉服务的具体内容和操作，一旦您使用本服务，即代表您已经完全熟悉本服务，所有的操作均为您的个人行为，并对其负责。您理解，契富智能无需对您或对任何第三人承担任何责任。' },
            { bold: 0, text: '' },
            { bold: 1, text: '七、【知识产权】' },
            { bold: 0, text: '' },
            { bold: 1, text: '7.1 契富智能是爆单ERP的知识产权权利人。爆单ERP（包括爆单ERP应用整体及爆单ERP涉及的所有服务、组成部分或构成要素）的一切著作权、商标权、专利权、商业秘密等知识产权及其他合法权益，以及与爆单ERP相关的所有信息服务（包括文字、图片、音频、软件、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和中华人民共和国缔结的相应的国际条约保护，契富智能享有上述知识产权和合法权益，但相关权利人依照法律规定应享有的权利除外。未经契富智能事先书面同意，您不得以任何方式将爆单ERP（包括爆单ERP应用整体及爆单ERP涉及的所有服务、组成部分或构成要素）进行商业性使用。' },
            { bold: 0, text: '' },
            { bold: 0, text: '7.2' },
            { bold: 0, text: '' },
            { bold: 1, text: '爆单ERP可能涉及第三方知识产权，如该第三方权利人对您基于本协议使用该等知识产权有要求的，契富智能将以适当方式向您告知该要求，您应当一并遵守。' },
            { bold: 0, text: '' },
            { bold: 0, text: ' ' },
            { bold: 0, text: '' },
            { bold: 1, text: '八、【服务的中止、终止及变更】' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.1' },
            { bold: 0, text: '' },
            { bold: 0, text: '本服务的中止或终止包含如下情况：' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.1.1您主动中止或终止的，包括但不限于到期未进行续费等；' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.1.2您存在违约行为，契富智能主动中止或终止服务的；' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.1.3契富智能根据国家或相关部门要求或发生不可抗力事件等而中止或终止服务；' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.1.4其他根据法律、法规、政策，或主管部门要求，或本协议约定应中止或终止服务的情形。' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.2' },
            { bold: 0, text: '' },
            { bold: 0, text: '中止或终止服务后，契富智能有权利但无义务确保您收到特别提示或通知。当您发现无法登录或享受服务时，可以咨询我们的客服人员。' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.3当发生第8.1条约定的中止或终止情形时：' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.3.1除法律规定的责任外，契富智能不对您和任何第三人承担任何责任；' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.3.2除本协议特别约定外，已收取的费用不予退还；' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.3.3正在进行的平台交易，契富智能将根据情况合理判断，是否继续进行或终止相关交易，并视情况决定是否进行退费或扣除相关费用作为违约金。' },
            { bold: 0, text: '' },
            { bold: 0, text: '8.4对于本协议或规则可能发生的变更（包括但不限于本协议修改、服务细则调整、会员权益调整等），契富智能将根据本协议的约定相应通知您，请您仔细阅读并选择是否按照调整和/或变更后的本协议/服务细则/会员权益等继续选择本服务。如您同意上述调整和/或变更，您可以采用接受服务的形式进行下一步操作或继续使用本服务，以表明您已经接受上述调整和/或变更；如您不同意调整和/或变更，我们建议您立即停止使用本服务。如您对上述调整和/或变更有任何问题，可以咨询客服。' },
            { bold: 0, text: '' },
            { bold: 0, text: ' ' },
            { bold: 0, text: '' },
            { bold: 1, text: '九、【通知】' },
            { bold: 0, text: '' },
            { bold: 0, text: '契富智能将根据实际情况，以尽可能合理的方式向用户发出本协议项下的各类通知，包括以下一种或多种方式送达：网页公告、站内消息、客户端推送、公众号通知、用户预留的电子邮件、手机短信、常规的信件传送或其他法律允许的方式。您同意并理解，本协议项下通知于发布、发送之日视为已送达用户。' },
            { bold: 0, text: '' },
            { bold: 0, text: '为便于您收取并了解相关通知，您应确保预留的联系方式为正确及有效，并在变更后及时登录爆单ERP进行修改；如因您未提供正确的联系信息或未及时告知新的联系方式，导致无法及时收取通知，将由您自行承担由此产生的损失及法律后果。' },
            { bold: 0, text: '' },
            { bold: 0, text: '同时，如您通过退订或主动屏蔽等方式拒绝或屏蔽通知，将可能无法收到前述通知，对此也将由您自行承担由此产生的损失及法律后果。' },
            { bold: 0, text: '' },
            { bold: 0, text: ' ' },
            { bold: 0, text: '' },
            { bold: 1, text: '十、【其他】' },
            { bold: 0, text: '' },
            { bold: 0, text: '10.1' },
            { bold: 0, text: '' },
            { bold: 1, text: '【协议的生效】' },
            { bold: 0, text: '' },
            { bold: 0, text: '当您采用接受服务的形式进行下一步操作、使用或继续使用本服务，即表明您已阅读并同意受本协议及修改后的协议的约束。' },
            { bold: 0, text: '' },
            { bold: 0, text: '10.2' },
            { bold: 0, text: '' },
            { bold: 1, text: '【协议签订地】' },
            { bold: 0, text: '' },
            { bold: 0, text: '本协议签订地为中华人民共和国广东省深圳市南山区。' },
            { bold: 0, text: '' },
            { bold: 0, text: '10.3' },
            { bold: 0, text: '' },
            { bold: 1, text: '【适用法律】' },
            { bold: 0, text: '' },
            { bold: 0, text: '本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。' },
            { bold: 0, text: '' },
            { bold: 0, text: '10.4' },
            { bold: 0, text: '' },
            { bold: 1, text: '【争议解决】' },
            { bold: 0, text: '' },
            { bold: 0, text: '若您和契富智能之间就本服务或其执行发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。' },
            { bold: 0, text: '' },
            { bold: 0, text: '10.5' },
            { bold: 0, text: '' },
            { bold: 1, text: '【条款标题】' },
            { bold: 0, text: '' },
            { bold: 0, text: '本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。' },
            { bold: 0, text: '' },
            { bold: 0, text: '10.6' },
            { bold: 0, text: '' },
            { bold: 1, text: '【条款效力】' },
            { bold: 0, text: '' },
            { bold: 0, text: '本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。' },
            { bold: 0, text: '' },
            { bold: 0, text: '（正文完）' },
        ]
        return (
            <React.Fragment>
                <div className="bodyBox">
                    <div className="agreementHeader">爆单ERP系列会员服务协议</div>
                    <br />
                    <br />
                    {
                        contents.map(item => {
                            return <div className={item.bold ? "agreementcontent agreementTitle" : "agreementcontent"}>{item.text}</div>
                        })
                    }

                    {/* <div style={{textAlign:"right"}}>本版本更新时间：{CommonUtils.dateFtt("yyyy-MM-dd",new Date())}</div> */}
                    <div style={{textAlign:"right"}}>本版本更新时间：2023年5月17日</div>
                    <div style={{textAlign:"right"}}>深圳契富智能科技有限公司</div>


                </div>
            </React.Fragment>
        );
    }
}

export default Agreement;