import React from "react";
import { distinguishBasic } from "../http/api";
import CommonUtils from '../http/Common';
import TextImageCard from './components/TextImageCard';
// import QueueAnim from 'rc-queue-anim';
import { Descriptions, Divider, Button, Checkbox, Form, Input, Radio, Space, message, } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

class Product extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         dialog: null
      };
   }

   componentDidMount() {
      let _this = this;
      CommonUtils.goToTop()//回到顶部

   }


   render() {
      const { } = this.state;

      const contentTextObj = [
         {
            title: "采集商品",
            content: ["批量采集商品、单品采集，一键搞定！",
               "特别注意，产品重量尽量填写准确（具体品类的重量可问1688客服）。",
               "因为没有准确设置重量， 计算出的藏价会比实际的运费少，", "而导致在物流费上面亏很多钱。"],
            img: "/img/erp_download_params.jpg",
         },
         {
            title: "商品上传",
            content: ["一键上传，自动生成视频，自动添加标签。", "标题自动分词，可添加公共商品描述！", "折扣建议设置30%以上，实现先提价再降价，以后想涨价，直接降低折扣即可，",
               "否则如果直接修改价格，会被平台误认为恶意调价，从而降低搜索排名。"],
            img: "/img/erp_upload.jpg",
         },
         {
            title: "商品编辑",
            content: ["您可以编辑商品的所有属性。可以生成/保留视频！", "修改标题、规格、尺寸、重量， 可以选择尺码图片等。"],
            img: "/img/erp_edit.jpg",
         },
         {
            title: "关注大卖店铺的粉丝",
            content: ["关注同品类大卖店铺的粉丝，一定程度上可以说是精准引流！","当大卖店铺的粉丝回关您之后，您上新商品时，平台会推送给粉丝。"],
            img: "/img/erp_plus_tools.jpg",
         },
         {
            title: "自动确认平台删除",
            content: ["商品过多时，平台经常会将浏览量较低的商品下架。","身为卖家，您的时间很宝贵,该功能为您一键搞定！"],
            img: "/img/erp_autodelete.jpg",
         },
         {
            title: "收集需求，不断升级",
            content: ["我们将不断的升级软件的功能！", "您在不经意间的一个小想法，都可以提交给我们！","我们会尽一切可能，帮您实现！"], 
            img: "/img/erp_feedback.jpg",
         },
      ]

      return (
         <React.Fragment>
            {/* <QueueAnim type={"bottom"} key={"queue"} duration={900} ease="easeInOutQuart" > */}
               {
                  contentTextObj.map((content, index) => {
                     content.index = index
                     return (<TextImageCard content={content} key={index} />)
                  })
               }
            {/* </QueueAnim> */}
         </React.Fragment>
      );
   }
}

export default Product;