import React from "react";
import { Layout, Menu, Col, Row, Image, Button, Space } from 'antd';
import { Checkbox, Form, Input, message, Affix, Dropdown, Modal, FloatButton, Tooltip, QRCode } from 'antd';
import { LockOutlined, UnlockOutlined, ArrowUpOutlined, WechatOutlined, VerticalAlignTopOutlined, DownOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import CommonUtils from './http/Common';
import { register, getcode, login, loginByToken, } from "./http/api";

var menuItems = [
  { label: "首页", key: "/home" },
  { label: "产品", key: "/product" },
  { label: "订购", key: "/price" },
  { label: "下载", key: "/download" },
  { label: "帮助教程", key: "/help" },
  { label: "", key: "" },
  { label: "", key: "" },
  { label: "", key: "" },
  { label: "", key: "" },
]

var managerMenu = [
  { label: "优惠码", key: "/discount" },
  { label: "投诉建议", key: "/feedback" },
  { label: "系统设置", key: "/setting" },
  { label: "", key: "" },
  { label: "", key: "" },
  { label: "", key: "" },
  { label: "", key: "" },
  { label: "", key: "" },
  { label: "", key: "" },
]

//刷新界面时选中菜单
var menuDefaultKey = "/home"
var url_hash = window.location.hash
var allMenus = menuItems.concat(managerMenu);//数组合并
allMenus.map(item => {
  if (item.key != "" && url_hash.includes(item.key)) {
    menuDefaultKey = item.key
    console.log(menuDefaultKey)
  }
})

const { Header, Content, Footer } = Layout;
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      loginDialog: false,
      registerDialog: false,
      reset: false,  //修改密码
      agreement: true, //同意协议
      leaveTime: 0, //短信重发时间
      activateMenuKey: menuDefaultKey, //当前选中的菜单
    };
  }


  componentDidMount() {
    let _this = this;

    //获取缓存的用户信息
    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    // console.log(user_cache)
    if (user_cache && user_cache.loginTime) {
      var loginTime = user_cache.loginTime
      console.log('loginTime===>', CommonUtils.dateFtt("yyyy-MM-dd hh:mm:ss", new Date(loginTime)))

      if ((new Date()).getTime() - loginTime < 1000 * 60 * 60 * 24 * 7) {//7天登陆一次
        this.setState({
          user: user_cache
        })
      }
    }

    //判断是不是直接跳转的注册
    var register = CommonUtils.GetQueryString("register")
    var reset = CommonUtils.GetQueryString("reset")
    if (register == 1) {
      this.setState({
        registerDialog: true,
        reset: reset ? true : false,
        loginDialog: false,
      });
    }

    //判断是不是直接跳转的登录
    var login = CommonUtils.GetQueryString("login")
    if (login == "true") {
      this.setState({
        registerDialog: false,
        reset: false,
        loginDialog: true,
      });
    }

    //判断是不是携带token跳转的
    var token = CommonUtils.GetQueryString("token")
    if (token) {
      CommonUtils.setLocalStorage("token", token)
      loginByToken({}).then(res => {
        if (res && res.data && res.data.success) {
          var resData = res.data.data
          resData.user.loginTime = (new Date()).getTime()
          CommonUtils.setLocalStorage("user", resData.user)
          CommonUtils.setLocalStorage("roleData", resData.roleData)

          message.success("登陆成功！")
          _this.setState({
            user: resData.user,
            registerDialog: false,
            loginDialog: false,
          })

        } else {
          message.success("登陆失败！请重新登录！")
          _this.setState({
            user: null,
            registerDialog: false,
            loginDialog: false,
          })
        }

        CommonUtils.delQueryString("login");//去除search参数
        CommonUtils.delQueryString("register");//去除search参数
        CommonUtils.delQueryString("reset");//去除search参数
        CommonUtils.delQueryString("token");//去除search参数
      })
    }







    //百度统计
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?89de42d94dbcde0193fe2ca6bb420ee8";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();

  }

  handleClose = () => {
    this.setState({
      registerDialog: false,
      loginDialog: false,
    });
  }


  //注册
  registerDialogFinish = (values) => {
    let _this = this;
    if (!values.username) {
      message.info("请输入手机号码！")
      return;
    }
    if (!values.password) {
      message.info("请输入密码！")
      return;
    }

    if (values.password.length < 6) {
      message.info("请设置超过6位数的密码！")
      return;
    }

    if (!values.password1) {
      message.info("请输入确认密码！")
      return;
    }
    if (!values.code) {
      message.info("请输入验证码！")
      return;
    }

    if (!this.state.agreement) {
      message.info("请阅读并同意 《用户服务协议》！")
      return;
    }

    if (values.password != values.password1) {
      message.info("两次密码不一致！")
      return;
    }

    var params = {
      password: values.password,
      phone: values.username,
      code: values.code,
    }

    var recommend = CommonUtils.GetQueryString('recommend')
    if (null != recommend) {
      params['recommend'] = recommend
    }


    register(params).then(res => {
      if (res && res.data && res.data.success) {
        message.success("操作成功,请登陆！")
        _this.setState({
          registerDialog: false,
          loginDialog: true,
          agreement: true,
        })

        CommonUtils.delQueryString("register");//去除search参数
        CommonUtils.delQueryString("reset");//去除search参数
      } else {
        message.info("操作失败，请稍后再试！")
      }
    })

  };

  //登陆按钮
  onFinish = (values) => {
    let _this = this;

    if (!values.username) {
      message.info("请输入手机号码！")
      return;
    }
    if (!values.password) {
      message.info("请输入密码！")
      return;
    }
    if (!this.state.agreement) {
      message.info("请阅读并同意 《用户服务协议》！")
      return;
    }

    var params = {
      phone: values.username,
      password: values.password,
    }

    login(params).then(res => {
      if (res && res.data && res.data.success) {
        var resData = res.data.data
        resData.user.loginTime = (new Date()).getTime()
        CommonUtils.setLocalStorage("token", resData.token)
        CommonUtils.setLocalStorage("user", resData.user)
        CommonUtils.setLocalStorage("roleData", resData.roleData)


        message.success("登陆成功！")
        _this.setState({
          user: resData.user,
          registerDialog: false,
          loginDialog: false,
        })

        CommonUtils.delQueryString("login");//去除search参数

        //如果有拼团参数，或者优惠码参数， 主动刷新一下页面

        var groupCode = CommonUtils.GetQueryString("gc") + "" //拼团码链接
        var discountCode = CommonUtils.GetQueryString("dc") + "" //优惠码链接
        if ((groupCode && groupCode.length == 8) || (discountCode && discountCode.length == 8)) {
          window.location.reload();//刷新页面
        }

      } else {
        if (res.data.message) {
          message.info(res.data.message)
        } else {
          message.info("登陆失败，请稍后再试！")
        }
      }
    })
  };

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  registerFun = () => {
    this.setState({
      registerDialog: true,
      loginDialog: false,
    })
  }

  loginFun = () => {
    this.setState({
      loginDialog: true,
      registerDialog: false,
    })
  }

  agreement_fun = () => {
    console.log(this.state.agreement)
    this.setState({
      agreement: !this.state.agreement
    })
  }


  getCode_fun = () => {
    let _this = this;
    console.log("getCode_fun")

    var register_phone = document.getElementById("register_phone").value
    if (!register_phone) {
      message.info("请输入手机号码！")
      return;
    }

    if (!CommonUtils.isPoneAvailable(register_phone)) {
      message.info("手机号码错误！")
      return;
    }

    if (_this.state.leaveTime > 0) {
      message.info("请稍后再试！")
      return;
    }

    this.setState({
      leaveTime: 60,
    }, function () {
      window.getcode = setInterval(function () {
        var leaveTime_old = _this.state.leaveTime
        leaveTime_old = leaveTime_old - 1
        if (leaveTime_old <= 0) {
          clearInterval(window.getcode)
        }
        _this.setState({
          leaveTime: leaveTime_old
        })
      }, 1000)
    })

    getcode({ phone: register_phone }).then(res => {
      if (res && res.data && res.data.success) {
        message.info("发送成功！")
      } else {
        message.info("发送失败，请稍后再试！")
      }
    })

    console.log("register_phone===>", register_phone)
  }


  //忘记密码
  forgetPasswordFun = () => {
    this.setState({
      registerDialog: true,
      reset: true,
      loginDialog: false,
    });
  }

  logout = () => {
    CommonUtils.setLocalStorage("token", null)
    CommonUtils.setLocalStorage("user", null)
    CommonUtils.setLocalStorage("roleData", null)

    this.setState({
      user: null,
    })
    window.location.hash = "" //返回首页并刷新

    message.success("退出登陆成功！")
  }

  menuDropdownFun = (value) => {
    this.setState({
      activateMenuKey: value.key
    })
  }

  render() {
    const { agreement, leaveTime, user, reset, activateMenuKey } = this.state;

    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    var validMenuItems = menuItems;
    if (user && user.userId && user.userId == 10000) {
      validMenuItems = menuItems.concat(managerMenu);//数组合并
    }

    var activateMenuLabel = "首页"

    var itemsHeaderMenu = []
    validMenuItems.map(i => {
      if (i.label != "") {
        itemsHeaderMenu.push({
          label: (
            <NavLink to={i.key}>
              <span>{i.label}</span>
            </NavLink>
          ),
          key: i.key,
        })
      }

      if (activateMenuKey == i.key) {
        activateMenuLabel = i.label
      }
    })

    //target="_blank"
    const menu = (
      <Menu
        items={[
          {
            key: '1',
            label: (
              <NavLink to="/profile" >
                <span  >个人中心</span>
              </NavLink>
            ),
          },
          {
            key: '4',
            danger: true,
            label: (<a onClick={this.logout}>
              退出登陆
            </a>),
          },
        ]}
      />
    );

    var MenuRate = isPhone ? 9 : 14
    var header_logoRate = isPhone ? 10 : 5
    var header_divRate = isPhone ? 24 : 18

    return (
      <React.Fragment>


        <Layout className="layout">

          <Affix offsetTop={0}>
            <Header className={isPhone ? "fixed-top navbarStyle navbarStylePhone" : "fixed-top navbarStyle"}>
              <Row justify={"center"} align={"middle"} >
                <Col xs={header_divRate} sm={header_divRate} md={header_divRate} lg={header_divRate} xl={header_divRate} xxl={header_divRate}>
                  <Row>
                    <Col xs={header_logoRate} sm={header_logoRate} md={header_logoRate} lg={header_logoRate} xl={header_logoRate} xxl={header_logoRate}>
                    <a href="/" style={isPhone ? {marginLeft:"23px"}: {}}>
                        <Image className="header_logo" src="/img/logo.png" width={35} height={35} preview={false}></Image>
                        <span className={isPhone ? "textOcrTitlePhone" : "textOcrTitle"}  >爆单ERP</span>
                      </a>
                    </Col>
                    <Col xs={MenuRate} sm={MenuRate} md={MenuRate} lg={MenuRate} xl={MenuRate} xxl={MenuRate}>
                      <Menu className="headerMenu" mode="horizontal" defaultSelectedKeys={[menuDefaultKey]} items={itemsHeaderMenu} style={{ fontSize: "18px" }} />

                      <Dropdown menu={{ items: itemsHeaderMenu, onClick: this.menuDropdownFun }} className="headerMenuPhone" >
                        <a >
                          <Space>
                            {activateMenuLabel}
                            <DownOutlined />
                          </Space>
                        </a>
                      </Dropdown>

                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                      {user ?
                        // <Dropdown menu={menu}>
                        //   <NavLink to="/profile" >
                        //     <Image className="user_head" src="/img/user_head.png" width={45} height={45} preview={false}></Image>
                        //   </NavLink>
                        // </Dropdown>

                        <NavLink to="/profile" >
                          <Image className="user_head" src="/img/user_head.png" width={45} height={45} preview={false}></Image>
                        </NavLink>
                        :
                        <>
                          <Button type="primary" className="headBtn" size='small' onClick={this.loginFun}>登录</Button>
                          {
                            isPhone ? null : <Button size='small' className="headBtn" onClick={this.registerFun}>注册</Button>
                          }

                        </>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Header>
          </Affix>
          <Content>
            <div className="site-layout-content" >
              {this.props.children}
            </div>
          </Content>

          <Footer style={{ textAlign: 'center', }} >
            <Image className="footerImg" preview={false} width={18} height={18} src="/img/gxb.png"></Image>
            <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" className="gongxinbu" target="_blank" rel="noreferrer">粤ICP备2021011472号-2</a>
            {'Copyright © '}
            {'深圳契富智能科技有限公司 '}
            {new Date().getFullYear()}
            {'.'}

          </Footer>


          <Modal
            open={this.state.loginDialog}
            onCancel={this.handleClose}
            width={800}
            className="loginModal"
            footer={null}
          >
            <div className="loginStyle" style={{ backgroundImage: "url('/img/login_img.png')" }}>
              <div className={isPhone ? "login_boxPhone" : "login_box"}>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                  autoComplete="off"
                >
                  <div className="login_title">爆单ERP</div>
                  <Form.Item
                    name="username"
                  >
                    <Input type='number' className="login_input" placeholder="请输入手机号码" prefix={<img src="/img/user.png" className="login_input_img"></img>} />
                  </Form.Item>

                  <Form.Item
                    name="password"
                  >
                    <Input.Password className="login_input" placeholder="密码" prefix={<LockOutlined className="input_icon" />} />
                  </Form.Item>


                  <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                    <Button type="primary" htmlType="submit" className="login_submit">
                      登陆
                    </Button>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                    <div className="xiyimarginleft">
                      <Checkbox onChange={this.agreement_fun} checked={agreement}></Checkbox>
                      <span> 我已阅读并同意</span> <span className="register_xieyi">
                        <NavLink to="/agreement" target="_blank">
                          <span  >《爆单ERP系列会员服务协议》</span>
                        </NavLink>
                      </span>
                    </div>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                    <span className="forgetPassword" onClick={this.registerFun}>去注册</span>
                    <span className="forgetPassword" onClick={this.forgetPasswordFun}>忘记密码</span>
                  </Form.Item>
                </Form>
              </div>
            </div>


          </Modal>

          <Modal
            open={this.state.registerDialog}
            onCancel={this.handleClose}
            width={800}
            className="loginModal"
            footer={null}
          >
            <div className="loginStyle" style={{ backgroundImage: "url('/img/login_img.png')" }}>
              {/* <div className="register_box" style={isPhone ? { right: '49px' } : {}}> */}
              <div className={isPhone ? "register_boxPhone" : "register_box"}>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  onFinish={this.registerDialogFinish}
                  onFinishFailed={this.onFinishFailed}
                  autoComplete="off"
                >
                  <div className="login_title">爆单ERP</div>
                  <Form.Item name="username" >
                    <Input type='number' className="login_input" id="register_phone" placeholder="请输入手机号码" prefix={<img src="/img/user.png" className="login_input_img"></img>} />
                  </Form.Item>

                  <Form.Item name="password" >
                    <Input.Password className="login_input" placeholder="密码" prefix={<LockOutlined className="input_icon" />} />
                  </Form.Item>

                  <Form.Item name="password1" >
                    <Input.Password className="login_input" placeholder="确认密码" prefix={<LockOutlined className="input_icon" />} />
                  </Form.Item>

                  <Form.Item name="code" >
                    <Input.Search type='number' className="login_input" id="codeinput" placeholder="验证码" onSearch={this.getCode_fun} enterButton={leaveTime ? leaveTime + "秒后重发" : "获取验证码"} prefix={<UnlockOutlined className="input_icon" />} />
                  </Form.Item>


                  <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                    <Button type="primary" htmlType="submit" className="login_submit">
                      {reset ? "重置密码" : "注册"}
                    </Button>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                    <div className="">
                      <Checkbox onChange={this.agreement_fun} checked={agreement}></Checkbox>
                      <span> 我已阅读并同意</span> <span className="register_xieyi">
                        <NavLink to="/agreement" target="_blank">
                          <span  >《爆单ERP系列会员服务协议》</span>
                        </NavLink>
                      </span>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>


          </Modal>

        </Layout>

        <FloatButton.Group >
          <Tooltip title={
            <Space wrap>
              <Image src="/img/customerServiceQrcode.png" preview={false} width={138} height={138} />
              <div style={{ color: "#999dab", textAlign: "center", width: "138px" }}>24小时微信咨询</div>
            </Space>
          } color="#FFF" placement="left" overlayStyle={{ width: "153px" }}>
            <FloatButton icon={<WechatOutlined style={{ color: "#00c250" }} />} />
          </Tooltip>


          <FloatButton.BackTop icon={<ArrowUpOutlined style={{ color: "#b4bddd" }} />} />
          {/* <FloatButton /> */}
        </FloatButton.Group>

      </React.Fragment>
    );
  }


};
export default App;
