import './index.css'
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router, Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';

import Homepage from "./pages/Homepage.js";
import Product from "./pages/Product.js";
import Price from "./pages/Price.js";
import Download from "./pages/Download.js";
import Test from "./pages/Test.js";
import Feedback from "./pages/Feedback.js";
import Help from "./pages/Help.js";
import Profile from "./pages/Profile.js";
import Discount from "./pages/Discount.js";
import Agreement from "./pages/Agreement.js";
import Setting from "./pages/Setting.js";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Switch>
      {/* 这里的exact={true}必须要加, 不然/login/:token也会匹配到这个url */}
      {/* <Route exact={true} path="/v" component={videoCall} /> */}
      {/* <Route path="/login/:token" component={Homepage} /> */}
      <Route path="/test" component={Test} />

      <Route path="/" render={() =>
        //--- 兼容旧版浏览器 :  `hashPriority` 默认为 `low`，配置为 `high` 后，会移除 `:where` 选择器封装 ; Ant Design 使用了 CSS 逻辑属性 `transformers` 提供预处理功能将样式进行转换  
        <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
          <App>
            <Switch>
              <Route path='/home' component={Homepage} />
              <Route path='/product' component={Product} />
              <Route path='/price' component={Price} />
              <Route path='/download' component={Download} />
              <Route path='/feedback' component={Feedback} />
              <Route path='/help' component={Help} />
              <Route path='/discount' component={Discount} />
              <Route path='/profile' component={Profile} />
              <Route path='/agreement' component={Agreement} />
              <Route path='/setting' component={Setting} />


              <Redirect from="/" to="/home" component={Homepage} />
              <Route component={Homepage} />
            </Switch>
          </App>
        </StyleProvider>


      } />
    </Switch>
  </HashRouter>
);

