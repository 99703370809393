import React from "react";
import { distinguishBasic } from "../http/api";
import CommonUtils from '../http/Common';
import { message, Image, Col, Button, Descriptions, Statistic, } from 'antd';
const { Countdown } = Statistic;


class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    };
  }

  componentDidMount() {
    CommonUtils.goToTop()//回到顶部

    //获取缓存的用户信息
    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    if (user_cache && user_cache.loginTime) {
      var loginTime = user_cache.loginTime

      if ((new Date()).getTime() - loginTime < 1000 * 60 * 60 * 24 * 7) {//7天登陆一次
        this.setState({
          user: user_cache
        })
      } else {
        message.info("请登陆！")
      }

    } else {
      message.info("请登陆！")
    }

    var roleData = CommonUtils.getLocalStorage("roleData");//缓存用户信息
    if (roleData) {
      this.setState({
        roleData: roleData,
      })
    }


  }

  logout = () => {
    CommonUtils.setLocalStorage("token", null)
    CommonUtils.setLocalStorage("user", null)
    CommonUtils.setLocalStorage("roleData", null)

    this.setState({
      user: null,
    })
    window.location.hash = "" //返回首页并刷新
    window.location.reload()

    message.success("退出登陆成功！")
  }


  render() {
    const { user, roleData, } = this.state;
    return (
      <React.Fragment>
        <div className="profileHead">
          <Image className="profileHead_img" src="/img/user_banner.png" width={"100%"} preview={false}  ></Image>

          <div className="profile_user_box">
            {
              user ?
                <Descriptions size={"middle"} title={<Image src="/img/user_head.png" width={55} height={55} preview={false}></Image>} >
                  <Descriptions.Item label="公司名称">{user.company}</Descriptions.Item>
                  <Descriptions.Item label="手机号">{user.phone}</Descriptions.Item>
                  <Descriptions.Item label="ERP ID">{user.userId}</Descriptions.Item>
                  {
                    roleData ?
                      <>
                        <Descriptions.Item label="订购店铺数">{roleData.shopCount} 个</Descriptions.Item>
                        {/* <Descriptions.Item label="订购周期">{roleData.month} 个月</Descriptions.Item> */}
                        <Descriptions.Item label="店铺到期时间">{roleData.shopTime}</Descriptions.Item>
                        <Descriptions.Item label="店铺剩余有效期"><span className="priceTime"><Countdown value={roleData.shopTimeStamp} format="D 天 H 时 m 分 s 秒" /></span></Descriptions.Item>

                        <Descriptions.Item label="增值功能-图片翻译">{roleData.translateImg + "次"}</Descriptions.Item>
                        <Descriptions.Item label="增值功能-文本翻译">{roleData.translateText + "字符"}</Descriptions.Item>
                        <Descriptions.Item label="增值功能-关注大卖粉丝">{roleData.fans + "个店铺" }</Descriptions.Item>
                        {
                          roleData.fansTime ?  <Descriptions.Item label="关注大卖粉丝到期时间">{roleData.fansTime}</Descriptions.Item> : null
                        }
                        


                      </>
                      : null
                  }
                  <Descriptions.Item label=""> <Button type="primary" size="small" onClick={this.logout}> 退出登录</Button></Descriptions.Item>
                </Descriptions>

                : null
            }

          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Profile;