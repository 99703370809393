import React from "react";
import { distinguishBasic } from "../http/api";
import CommonUtils from '../http/Common';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Radio, Space, Divider, Tabs } from 'antd';


class Download extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         dialog: null
      };
   }

   componentDidMount() {
      let _this = this;
      CommonUtils.goToTop()//回到顶部

   }

   downloadFun = (appTtype) => {
      console.log("downloadFun", appTtype)
      if (appTtype == "window") {
         // window.open("https://kyl-erp.oss-cn-shenzhen.aliyuncs.com/exe/DragonERP.rar")
         window.open("https://kyl-erp.oss-cn-shenzhen.aliyuncs.com/exe/%E7%88%86%E5%8D%95ERP%28%E5%AE%89%E8%A3%85%E5%8C%85%29.exe")
      } else if (appTtype == "macIntel") {
         window.open("https://kyl-erp.oss-cn-shenzhen.aliyuncs.com/exe/intel/%E7%88%86%E5%8D%95ERP.dmg")
      } else if (appTtype == "macApple") {
         window.open("https://kyl-erp.oss-cn-shenzhen.aliyuncs.com/exe/%E7%88%86%E5%8D%95ERP.dmg")
      }
   }



   render() {
      const { } = this.state;


      return (
         <React.Fragment>
            {/* <div style={{ backgroundImage: "url('/img/download_bg.jpg')", minHeight: "100vh" }}> */}
            <div style={{ minHeight: "100vh" }}>
               <div className="textCenter">
                  <div className="bodyBox">
                     {/* <div className="downloadTitle">爆单ERP Windows 版</div>
                     <Button className="downloadBtn" shape="round" icon={<DownloadOutlined />} size={"large"} onClick={this.downloadFun} > 
                        立即下载
                     </Button>
                     <div>支持平台：  采集阿里巴巴1688， 上架shpoee虾皮平台。持续更新升级中。</div> */}


                     <Tabs defaultActiveKey="1" items={[
                        {
                           key: '1',
                           label: <>
                              <div>
                                 <div>
                                    <svg class="sys_svg_icon" viewBox="0 0 50 50" width="50" height="50">
                                       <path d="M7 23.977l13.282-.176v-14.384l-13.282 2.286v12.274zm15.953-15.02v14.809l20.047-.266v-17.993l-20.047 3.45zm-15.953 29.339l13.282 2.286v-14.383l-13.282-.176v12.273zm15.953 2.746l20.047 3.451v-17.994l-20.047-.265v14.808z"></path>
                                    </svg>
                                 </div>
                                 <div>
                                    <span>Windows版</span>
                                 </div>

                              </div>
                           </>,
                           children: <>
                              <div className="downloadTitle">爆单ERP Windows 客户端</div>
                              <Button className="downloadBtn" shape="round" icon={<DownloadOutlined />} size={"large"}
                                 onClick={() =>this.downloadFun("window")} >
                                 立即下载
                              </Button>
                              <div>适用于 Windows7 及以上版本。</div>
                           </>,
                        },
                        {
                           key: '2',
                           label: <>
                              <div>
                                 <div>
                                    <svg class="sys_svg_icon" viewBox="0 0 50 50" width="50" height="50">
                                       <path d="M42.705 17.437c-2.28-2.958-5.474-4.671-8.49-4.671-3.99 0-5.675 1.976-8.454 1.976-2.85 0-5.022-1.964-8.478-1.964-3.384 0-6.993 2.143-9.285 5.796-3.218 5.15-2.672 14.85 2.541 23.114 1.864 2.958 4.358 6.275 7.623 6.312h.06c2.838 0 3.681-1.928 7.587-1.952h.06c3.847 0 4.619 1.94 7.445 1.94h.059c3.265-.036 5.889-3.713 7.753-6.659 1.342-2.12 1.84-3.185 2.873-5.581-7.54-2.97-8.751-14.059-1.294-18.311zm-18.155-4.371c2.529 0 5.118-1.581 6.625-3.605 1.461-1.928 2.565-4.658 2.162-7.461-2.375.168-5.141 1.736-6.768 3.784-1.472 1.856-2.683 4.611-2.208 7.282h.189z"></path>
                                    </svg>
                                 </div>
                                 <div>
                                    <span>macOs版(Intel)</span>
                                 </div>

                              </div>
                           </>,
                           children: <>
                              <div className="downloadTitle">爆单ERP Mac 客户端</div>
                              <Button className="downloadBtn" shape="round" icon={<DownloadOutlined />} size={"large"}
                               onClick={() =>this.downloadFun("macIntel")} >
                                 立即下载
                              </Button>
                              <div>适用于 macOS 10.13 High Sierra 及以上，Intel芯片Mac设备。</div>
                           </>,
                        },
                        {
                           key: '3',
                           label: <>
                              <div>
                                 <div>
                                    <svg class="sys_svg_icon" viewBox="0 0 50 50" width="50" height="50">
                                       <path d="M42.705 17.437c-2.28-2.958-5.474-4.671-8.49-4.671-3.99 0-5.675 1.976-8.454 1.976-2.85 0-5.022-1.964-8.478-1.964-3.384 0-6.993 2.143-9.285 5.796-3.218 5.15-2.672 14.85 2.541 23.114 1.864 2.958 4.358 6.275 7.623 6.312h.06c2.838 0 3.681-1.928 7.587-1.952h.06c3.847 0 4.619 1.94 7.445 1.94h.059c3.265-.036 5.889-3.713 7.753-6.659 1.342-2.12 1.84-3.185 2.873-5.581-7.54-2.97-8.751-14.059-1.294-18.311zm-18.155-4.371c2.529 0 5.118-1.581 6.625-3.605 1.461-1.928 2.565-4.658 2.162-7.461-2.375.168-5.141 1.736-6.768 3.784-1.472 1.856-2.683 4.611-2.208 7.282h.189z"></path>
                                    </svg>
                                 </div>
                                 <div>
                                    <span>macOs版(Apple)</span>
                                 </div>

                              </div>
                           </>,
                           children: <>
                              <div className="downloadTitle">爆单ERP Mac 客户端</div>
                              <Button className="downloadBtn" shape="round" icon={<DownloadOutlined />} size={"large"}
                               onClick={() =>this.downloadFun("macApple")} >
                                 立即下载
                              </Button>
                              <div>适用于 macOS 10.13 High Sierra 及以上，Apple芯片Mac设备。</div>
                           </>,
                        },
                     ]} />

                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}

export default Download;