import http from './http'

/**
 * 这里统一处理项目中所有的 api 接口
 */


export const getCode = params => http.post('/service/public/getCode',params,false);
export const searchByWord = params => http.get('/service/public/search',params,false);
export const exportProData = params => http.export('/service/monitorBusiness/exportProData',params,false);
export const exportInitData = params => http.exportPost('/service/monitorBusiness/exportInitData',params,false);

export const register = params => http.post('/service/erp/user/public/register',params,false);
export const getcode = params => http.post('/service/erp/user/public/getcode',params,false);
export const login = params => http.post('/service/erp/user/public/login',params,false);
export const loginByToken = params => http.post('/service/erp/user/loginByToken',params,false);
export const setDebug = params => http.post('/service/erp/user/setDebug',params,false);
export const makeDiscountCode = params => http.post('/service/pay/makeDiscountCode',params,false);
export const buyingVip = params => http.post('/service/pay/buyingVip',params,false);
export const makeGroupBuying = params => http.post('/service/pay/makeGroupBuying',params,false);
export const buyTools = params => http.post('/service/pay/buyTools',params,false);
export const getFeedback = params => http.post('/service/getFeedback',params,false);
export const getGroupBuying = params => http.get('/service/pay/getGroupBuying',params,false);
export const getDiscountCode = params => http.get('/service/pay/getDiscountCode',params,false);
export const getVersion = params => http.get('/service/erp/public/version',params,false);
export const setVersion = params => http.get('/service/erp/setversion',params,false);


export const distinguishBasic = params => http.uploadPost('/service/textocr',params,false);


