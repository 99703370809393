import React from "react";
import { Row, Col, Image, Button, notification, Radio, Tabs, Card } from "antd"


import MyBanner from "./components/MyBanner.js";

const { Meta } = Card;

class Test extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null
        };
    }

    render() {
        const { } = this.state;
        return (
            <React.Fragment>



                <Card hoverable style={{ width: 120, }} cover={<img src="img/ali.png" />} >
                   
                </Card>


            </React.Fragment>
        );
    }
}

export default Test;





