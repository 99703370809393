import { message } from 'antd';
const key = 'updatable';



class Common {
	//本地
	// static baseurl = "http://localhost:28887";
	// static baseWs = "ws://127.0.0.1:28887";

	//生产
	static baseurl = 'https://api.baodanerp.cn';
	static baseWs = "wss://api.baodanerp.cn/ws";



	//设置浏览器缓存
	static setLocalStorage(key, value) {
		localStorage.setItem(key, JSON.stringify(value))
	}

	//获取浏览器缓存
	static getLocalStorage(key) {
		var data = localStorage.getItem(key);
		if (null == data) {
			return null
		}
		return JSON.parse(data)
	}

	//组装登录URL
	static goLoginUrl() {
		localStorage.removeItem("user");
		localStorage.removeItem("token");

		if (!window.location.hash.includes("login")) {
			var href = window.location.href;
			if (!href.includes("?")) {
				href += "?";
			}
			if (!href.includes("login=true")) {
				href += "&login=true";
			}
			window.location.href = href;
		}

	}

	//检测是否登录
	static hasLogin() {
		//获取缓存的用户信息
		var user_cache = this.getLocalStorage("user");//缓存用户信息
		if (user_cache && user_cache.loginTime) {
			var loginTime = user_cache.loginTime
			console.log('loginTime===>', this.dateFtt("yyyy-MM-dd hh:mm:ss", new Date(loginTime)))

			if ((new Date()).getTime() - loginTime < 1000 * 60 * 60 * 24 * 7) {//7天登陆一次
				return true;
			} else {
				//提示登录
				message.info({ content: '请先登录！', key, duration: 1 });
				this.setLocalStorage("token", null)
				this.setLocalStorage("user", null)
				return false;
			}
		} else {
			//提示登录
			message.info({ content: '请先登录！', key, duration: 1 });
			this.setLocalStorage("token", null)
			this.setLocalStorage("user", null)
			return false;
		}
	}

	//一键复制
	static copyData(datatext) {
		// var datatext = document.getElementById(dataid).value
		var aux = document.createElement("textarea");
		aux.value = datatext;
		document.body.appendChild(aux);
		aux.select();
		document.execCommand("copy");
		document.body.removeChild(aux);
		message.success({ content: '复制成功', key, duration: 1 });;
	}


	//获取凌晨时间戳
	static getBeforeDawn() {
		var nowTime = new Date();
		nowTime.setMilliseconds(0);
		nowTime.setSeconds(0);
		nowTime.setMinutes(0);
		nowTime.setHours(0);//12小时之前的数据
		return nowTime.getTime();
	}

	//获取用户缓存
	static getUserVo() {
		try {
			var userMsg = JSON.parse(localStorage.getItem("3d915_user"));
			return userMsg;
		} catch (e) {
			return null;
		}
	}

	//回到顶部
	static goToTop() {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}

	// var newData = CommonUtils.dateFtt("yyyy-MM-dd hh:mm:ss",new Date(e._d));
	//时间戳转对应格式的时间   时间格式化
	static dateFtt(fmt, date) {
		var o = {
			"M+": date.getMonth() + 1,                 //月份   
			"d+": date.getDate(),                    //日   
			"h+": date.getHours(),                   //小时   
			"m+": date.getMinutes(),                 //分   
			"s+": date.getSeconds(),                 //秒   
			"q+": Math.floor((date.getMonth() + 3) / 3), //季度   
			"S": date.getMilliseconds()             //毫秒   
		};
		if (/(y+)/.test(fmt))
			fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
		for (var k in o)
			if (new RegExp("(" + k + ")").test(fmt))
				fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
		return fmt;
	}

	//判断是不是手机客户端
	static isPhoneClient(){
		var rate  = window.screen.availHeight/window.screen.availWidth
		if(rate && rate > 1){
			return true;
		}else{
			return false;
		}
	}

	//处理服务器端的导出
	static exportFile(resData, fileName) {
		let url = window.URL.createObjectURL(new Blob([resData]))
		let link = document.createElement('a')
		link.style.display = 'none'
		link.href = url
		link.setAttribute('download', fileName)    // 自定义下载文件名（如exemple.txt）
		document.body.appendChild(link);
		link.click();
	}


	//验证是不是手机号码
	static isPoneAvailable(str) {
		var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
		if (!myreg.test(str)) {
			return false;
		} else {
			return true;
		}
	}



	static downloadFile(url) {
		try {
			var elemIF = document.createElement("iframe");
			elemIF.src = url;
			elemIF.style.display = "none";
			document.body.appendChild(elemIF);
		} catch (e) {
			console.log("下载异常！");
		}
	}

	static myBrowser() {
		var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
		var isOpera = userAgent.indexOf("Opera") > -1;
		if (isOpera) { //判断是否Opera浏览器
			return "Opera"
		}

		if (userAgent.indexOf("Firefox") > -1) { //判断是否Firefox浏览器
			return "FF";
		}

		if (userAgent.indexOf("Chrome") > -1) {
			return "Chrome";
		}

		if (userAgent.indexOf("Safari") > -1) { //判断是否Safari浏览器
			return "Safari";
		}

		if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) { //判断是否IE浏览器
			return "IE";
		}
	}

	//获取地址栏的参数
	static GetQueryString(name) {
		try{
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.hash.split("?")[1].match(reg);
			if (r != null) {
				return (r[2]);
			}
		}catch {}
		
		return null;

	}

	//删除地址栏的参数
	static delQueryString(name) {
		try{
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.hash.split("?")[1].match(reg);
			if (r != null) {
				var newUrl = window.location.href.replace(r[0], "").replace(window.location.origin, "")
				window.history.pushState({}, '', newUrl);//不会重新刷新
			}
		}catch{}
	}

	//添加地址栏的参数
	static addQueryString(name,value) {
		try{
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.hash.match(reg);
			//先删除再添加
			var newUrl = window.location.href
			if (r != null) {
				newUrl = window.location.href.replace(r[0], "").replace(window.location.origin, "")
			}

			if(!newUrl.includes("?")){
				newUrl += "?"
			}
			newUrl += "&" + name + "=" + value

			window.history.pushState({}, '', newUrl);//不会重新刷新
		}catch{}
	}

	//滚动到某个id处
	static scrollToElement (id){
		try {
			var element = document.getElementById(id)
			window.scrollTo(0, element.offsetTop - 80)  //x 和y 坐标
		} catch (e) {
			console.log("滚动到某个位置异常！")
		}
	}



}

export default Common;
