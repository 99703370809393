
import React from "react";
import {Row,Col,Image,} from "antd"

// 展示左边文字右边图片
class TextImageCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.content
        };
    }

    render() {
        const { content } = this.state;
        return (
            <React.Fragment>
                <div className={content.index % 2 == 0 ? "contentRowBase" : "contentRowBackground"}>
                    <Row justify={"center"} align={"middle"} >
                        <Col offset={2} xs={20} sm={20} md={10} lg={10} xl={10} xxl={10}>
                            <div className="contentTitle">{content.title}</div>
                            {/* <div className="contentText">{content.content}</div> */}
                            {
                                content.content.map((item, index)=>{
                                    return <div className="contentText" key={index}>{item}</div>
                                })
                            }
                        </Col>
                        <Col xs={20} sm={20} md={20} lg={10} xl={10} xxl={10}>
                            <Image src={content.img} preview={false} className="imageCardImg"></Image>
                        </Col>
                    </Row>
                </div>

                {/* {this.props.children} */}
            </React.Fragment>
        );
    }
}

export default TextImageCard;