import React from "react";
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';
import CommonUtils from '../../http/Common';
const BgElement = Element.BgElement;

// 调用例子
// import MyBanner from "./components/MyBanner.js";
// var bannerData = [
//     {
//         "img": "/img/111.png", //不可为空
//         "title": "标题1",//可为空
//         "text": "文字home",//可为空
//         "url": "/home",//可为空
//     },
// ]

// <MyBanner bannerData={bannerData} height={"50vh"} />


class MyBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerData: props.bannerData ? props.bannerData : [],
        };
    }

    componentDidMount() {
        let _this = this;
    }


    imgClickFun = (url) => {
        if (url && url != "") {
            // window.open(url, "_blank")
            window.open(url, "_self")
        }
    }

    render() {
        const { bannerData } = this.state;

        return (
            <React.Fragment>
                <BannerAnim prefixCls="banner-user" autoPlay={true} style={{ height: this.props.height }}>
                    {
                        bannerData.map((data, index) => {
                            return (
                                <Element prefixCls="banner-user-elem" key={index} onClick={() => this.imgClickFun(data.url)}>
                                    <BgElement key="bg" className="bg" style={{ backgroundImage: `url(` + data.img + `)` }} />
                                    {
                                        data.title ?
                                            <TweenOne className="banner-user-title" animation={{ y: 30, opacity: 0, type: 'from' }}>
                                                {data.title}
                                            </TweenOne>
                                            : null
                                    }
                                    {
                                        data.text ?
                                            <TweenOne className="banner-user-text" animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }} >
                                                {data.text}
                                            </TweenOne>
                                            : null
                                    }

                                </Element>
                            )
                        })
                    }
                </BannerAnim>
            </React.Fragment>
        );
    }
}

export default MyBanner;