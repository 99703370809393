import React from "react";
import { distinguishBasic } from "../http/api";
import CommonUtils from '../http/Common';
import TextImageCard from './components/TextImageCard';
// import QueueAnim from 'rc-queue-anim';
import MyBanner from "./components/MyBanner.js";



class Homepage extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         dialog: null,
         isPhone : CommonUtils.isPhoneClient(),
      };
   }

   componentDidMount() {
      let _this = this;
      CommonUtils.goToTop()//回到顶部
   }


   render() {
      const {isPhone } = this.state;

      const contentTextObj = [
         {
            title: "功能强大，操作简单",
            content: ["批量采集商品、上架商品、处理订单，一键搞定！",
            "不用授权，不用频繁安装各种复杂的插件，不用过多的操作,","系统智能处理。让您成为市场上的明星卖家。"],
            img: "/img/showImg1.jpg",
         },
         {
            title: "成功率高",
            content: ["采集、上传的成功率高，不断升级迭代软件。","最性价比的ERP软件，让您的业务更简单、更高效！"],//跨境电商必备神器
            img: "/img/showImg2.jpg",
         },
         {
            title: "丰富资料",
            content: ["批量采集图片，规格，描述文字和描述图片,","并添加正品、优惠、包邮的标签,","自动合成视频等丰富商品资料。",
            "让我们的跨境电商ERP软件助您高效赚取更多的收益！"], 
            img: "/img/showImg3.jpg",
         },
         {
            title: "自动化工具",
            content: ["关注大卖粉丝、一键自动确认平台删除等自动化工具，","解放您的双手，提高处理效率。"],//身为卖家，您的时间很宝贵
            img: "/img/showImg4.jpg",
         },
         {
            title: "性价比最高",
            content: ["全网性价比最高的跨境电商ERP软件！","为您提供最优惠的价格，更强大的功能，让您省时省力省成本！","更低的运营成本，竞争力也会更加强劲！"], //小本创业也能做大事
            img: "/img/showImg5.jpg",
         },
      ]

      var bannerData = [
         {
            "img": "/img/banner3.jpg", //性价比最高   不可为空
            "url": "#/price",//可为空
         },
         {
            "img": "/img/banner1.jpg", //不可为空
            // "title": "标题1",//可为空
            // "text": "文字home",//可为空
            "url": "#/price",//可为空
         },
         {
            "img": "/img/banner2.jpg", //不可为空
            "url": "#/price",//可为空
         },
        
      ]



      return (
         <React.Fragment>
            <MyBanner bannerData={bannerData} height={isPhone ? "20vh" : "60vh"} />

            {/* <QueueAnim type={"bottom"} key={"queue"} duration={900} ease="easeInOutQuart" > */}
               {
                  contentTextObj.map((content, index) => {
                     content.index = index
                     return (<TextImageCard content={content} key={index} />)
                  })
               }
            {/* </QueueAnim> */}
         </React.Fragment>
      );
   }
}

export default Homepage;