import React from "react";
import { getDiscountCode, buyingVip, makeGroupBuying, getGroupBuying, buyTools } from "../http/api";
import CommonUtils from '../http/Common';
import ReactWebsocket from './components/ReactWebsocket.js';
import {
   Image, Radio, InputNumber, Input, Space, Button, Table, Checkbox, Avatar, Divider,
   Tooltip, Tag, QRCode, Statistic, message, Modal, Result, Typography, notification,
   Tabs, Card, Form, Popconfirm, Drawer,
} from 'antd';
import {
   CheckCircleOutlined, CloseCircleOutlined, CopyOutlined, QrcodeOutlined, QuestionCircleOutlined,
   RightOutlined, InfoCircleOutlined,
} from '@ant-design/icons';
import { NavLink } from 'react-router-dom';


const { Countdown } = Statistic;
const { Text } = Typography;



class Price extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         shopCount: 1, //店铺数量
         vipTime: 1, //订购周期 默认一个月
         discountCode: "",//优惠码
         originalPrice: null,//原价
         payablePrice: null,//应付价
         fans: false,//关注大卖粉丝
         translateImg: false,//加一元送图片翻译
         searchDiscountCode: null,
         finalTime: null,//优惠券倒计时
         disCountCodeType: null,
         disCountValue: null,
         payQrCodeUrl: null,//微信支付的二维码链接

         openWebsocket_group: false,//拼团websocket
         showModal_payGroup: false,//拼团支付
         showModal: false,//
         showModal_payQrCode: false,//
         showModal_payTools: false,//
         showBuyDrawer: false,//
         isGroupBuy: false,//是否是团购
         showModal_buygroup_info: false,//团购信息展示
         payTitle: "",//扫码支付的title
         websocket_pay: false,//打开支付回调的websoccket
         new100Phone: [],//团购
         resourcePackageCheckValue: [],//资源包选项
         expireStamp: null,
         // groupDiscount: null,
         groupDiscount: 9, //直接打九折
         groupCode: null,
         groupDiscountPrice: 0,//团购价

         imageTranslateCount: 1000,//图片翻译次数
         textTranslateCount: 1000000,//文本翻译次数
         fansCount: 1,//单买-关注粉丝店铺次数
         fansTime: 1,//

         TabsActiveKey: '1',// tab的活动key

      };
   }

   componentDidMount() {
      let _this = this;
      CommonUtils.goToTop()//回到顶部
      this.countPrice()//初始化价格

      //拼团码链接
      var groupCode = CommonUtils.GetQueryString("gc") + ""
      if (groupCode && groupCode.length == 8) {
         this.groupBuyingCodeFun(groupCode) // 查询拼团码
      }

      //优惠码链接
      var discountCode = CommonUtils.GetQueryString("dc") + ""
      if (discountCode && discountCode.length == 8) {
         this.discountCodeFun(discountCode) // 查询优惠码
      }
   }

   setShopCountFun = (value) => {
      console.log(value)
      this.setState({
         shopCount: value
      }, this.countPrice)

   }

   setVipTimeFun = (value) => {
      console.log(value)
      this.setState({
         vipTime: value
      }, this.countPrice)
   }

   shopCountInputFun = (value) => {
      console.log(value)
      this.setState({
         shopCount: parseInt(value) ? parseInt(value) : 1
      }, this.countPrice)
   }

   //优惠券查询
   discountCodeFun = (value) => {
      let _this = this;

      value = value ? value + "" : ""
      this.setState({
         discountCode: value,
         searchDiscountCode: null,
         disCountCodeType: null,
         disCountValue: null,
         finalTime: null,
         payQrCodeUrl: null,
      }, this.countPrice)

      if (value.length == 8) {
         // if (!CommonUtils.hasLogin()) {
         //    this.setState({
         //       discountCode: null
         //    })
         //    return
         // };//检测是否登录  --开放这个接口
         getDiscountCode({ "code": value }).then(res => {
            if (res && res.data && res.data.success) {
               let resData = res.data.data;
               // var searchDiscountCode = "优惠类型：" + (resData.disCountCodeType == "disCount" ? "折扣: " : "代金券: ")
               var searchDiscountCode = (resData.disCountCodeType == "disCount" ? "折扣: " : "代金券: ")
               searchDiscountCode += resData.disCountCodeType == "disCount" ? resData.disCountValue * 100 : resData.disCountValue
               searchDiscountCode += resData.disCountCodeType == "disCount" ? "% " : "元 "

               _this.setState({
                  searchDiscountCode: searchDiscountCode,
                  disCountCodeType: resData.disCountCodeType,
                  disCountValue: resData.disCountValue,
                  finalTime: resData.finalTime
               }, this.countPrice)

               if (resData.disCountNumber && resData.disCountNumber > 1) {
                  notification.success({
                     placement: 'topRight',
                     duration: 8,
                     message: "提示",
                     description: searchDiscountCode + ",还剩下：" + resData.disCountNumber + "个优惠券，请尽快使用，优惠券有可能被他人用完。"
                  })
               }


            } else {
               _this.setState({
                  searchDiscountCode: "该优惠码已失效",
                  disCountCodeType: null,
                  disCountValue: null,
                  finalTime: null
               }, this.countPrice)
            }
         });
      }
   }

   //计算价格
   countPrice = () => {
      const { shopCount, vipTime, fans, translateImg, disCountCodeType, disCountValue, groupDiscount, } = this.state;
      var basePrice = 6; //单个店铺单个月
      if (shopCount == 1 || shopCount == 2) {
         basePrice = 10;
      }
      var discountNum = 1; //订购周期折扣
      switch (vipTime) {
         case 3:
            discountNum = 0.88;
            break;
         case 6:
            discountNum = 0.78;
            break;
         case 12:
            discountNum = 0.68;
            break;
         case 36:
            discountNum = 0.5;
            break;
      }


      var originalPrice = basePrice * shopCount * vipTime * 2;

      var endPayMoney = basePrice * shopCount * vipTime * discountNum; //最终支付

      //关注粉丝
      if (fans) {
         originalPrice += shopCount
         endPayMoney += shopCount
      }

      //加一元送500张图片翻译
      if (translateImg) {
         originalPrice += shopCount
         endPayMoney += shopCount
      }

      if (disCountCodeType && disCountValue) {
         if (disCountCodeType == "disCount") {
            endPayMoney = endPayMoney * disCountValue
         } else {
            endPayMoney = endPayMoney - disCountValue
         }
      }


      if (endPayMoney < 1) {
         endPayMoney = 1
         message.info("最低1元!")
      }

      var groupDiscount_Temp = groupDiscount ? groupDiscount : 9

      var groupDiscountPrice = 0;
      if (groupDiscount_Temp) {//拼团
         groupDiscountPrice = endPayMoney * groupDiscount_Temp / 10;
         if (groupDiscountPrice < 1) {
            groupDiscountPrice = 1
            message.info("最低1元!")
         }
      }

      this.setState({
         originalPrice: parseInt(originalPrice),//原价
         payablePrice: parseInt(endPayMoney),//应付价
         groupDiscountPrice: parseInt(groupDiscountPrice),//团购价
      })

   }

   //立即支付按钮
   shopBuyingFun = (isGroupBuy) => {
      let _this = this;
      const { shopCount, vipTime, fans, translateImg, discountCode, payablePrice, groupCode, groupDiscountPrice, } = this.state;
      if (!CommonUtils.hasLogin()) { return };//检测是否登录

      // if (!groupCode) {
      //    message.info("您还未输入拼团号码，支付金额将不会打折。")
      // }

      var type = "shop";//"shop+fans+translateImg";
      if (fans) {
         type += "+fans"
      }
      if (translateImg) {
         type += "+translateImg"
      }

      var params = {
         buyCount: shopCount,//购买数量
         buyTime: vipTime,//购买时间  单位是：月
         type: type,//支付类型（用于购买什么的）  shop fans  translate
         discountCode: discountCode,//优惠码
         payablePrice: payablePrice,//上传前端的最终付款，用于核对计算值
         groupCode: groupCode,
         groupDiscountPrice: groupDiscountPrice,
         groupBuy: isGroupBuy,//是否是团购
      }


      buyingVip(params).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            console.log(resData)
            var payTitle = isGroupBuy ? groupDiscountPrice : payablePrice
            payTitle = "￥" + payTitle
            _this.setState({
               payTitle: payTitle,
               payQrCodeUrl: resData,
               openWebsocket_group: false,
               showModal_payQrCode: true,
               websocket_pay: true,
            })

         } else {
            _this.setState({
               payTitle: "",
               payQrCodeUrl: null,
               openWebsocket_group: false,
               showModal_payQrCode: false,
               websocket_pay: false,
            })
         }
      });
   }


   //订购图片翻译
   buyTranslateImage = () => {
      let _this = this;
      this.setStateKeyValue("showModal_translate", false)
      const { imageTranslateCount } = this.state;
      console.log("imageTranslateCount ::: ", imageTranslateCount)

      var params = {
         "type": "translateImg",
         "buyCount": imageTranslateCount,
         "payablePrice": 0.01 * imageTranslateCount,
      }

      buyTools(params).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            console.log(resData)

            _this.setState({
               payTitle: "订购图片翻译",
               payQrCodeUrl: resData,
               showModal_payQrCode: true,
               websocket_pay: true,
            })

         } else {
            _this.setState({
               payTitle: "",
               payQrCodeUrl: null,
               showModal_payQrCode: false,
               websocket_pay: false,
            })
         }
      })

   }

   //订购文本翻译
   buyTranslateImageText = () => {
      let _this = this;
      this.setStateKeyValue("showModal_translate_text", false)
      const { textTranslateCount } = this.state;
      console.log("textTranslateCount ::: ", textTranslateCount)

      var params = {
         "type": "translateText",
         "buyCount": textTranslateCount,
         "payablePrice": 0.00004 * textTranslateCount,
      }

      buyTools(params).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            console.log(resData)

            _this.setState({
               payTitle: "订购文本翻译",
               payQrCodeUrl: resData,
               showModal_payQrCode: true,
               websocket_pay: true,
            })

         } else {
            _this.setState({
               payTitle: "",
               payQrCodeUrl: null,
               showModal_payQrCode: false,
               websocket_pay: false,
            })
         }
      })

   }

   //订购关注大卖粉丝
   buyfans = () => {
      let _this = this;
      this.setStateKeyValue("showModal_follow_fans", false)
      const { fansCount, fansTime } = this.state;
      console.log(" fansCount,fansTime  ::: ", fansCount, fansTime)

      var params = {
         "type": "fans",
         "buyTime": fansTime,
         "buyCount": fansCount,
         "payablePrice": 5 * fansCount * fansTime,
      }

      buyTools(params).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            console.log(resData)

            _this.setState({
               payTitle: "订购关注大卖粉丝",
               payQrCodeUrl: resData,
               showModal_payQrCode: true,
               websocket_pay: true,
            })

         } else {
            _this.setState({
               payTitle: "",
               payQrCodeUrl: null,
               showModal_payQrCode: false,
               websocket_pay: false,
            })
         }
      })

   }

   setStateKeyValue = (key, value) => {
      this.setState({
         [key]: value
      })
   }

   //打开支付界面
   setShowBuyDrawer = (isGroupBuy) => {
      this.setState({
         isGroupBuy: isGroupBuy,
         showBuyDrawer: true,
      })
   }

   onChangeCheckbox = (value) => {
      this.setState({
         fans: value.includes("fans"),
         translateImg: value.includes("translateImg"),
         resourcePackageCheckValue: value,
      }, this.countPrice)


   }

   scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

   //tab 被点击的回调
   onTabClickFun = (key, event) => {
      if (key == 1 || key == 2) {
         this.setState({
            TabsActiveKey: key
         })
      }
      else if (key == 3) {
         CommonUtils.scrollToElement("part-permissions")
      } else if (key == 4) {
         CommonUtils.scrollToElement("part-ordering-instructions")
      }
   }

   //发起拼团
   makeGroupBuyingFun = () => {
      let _this = this;
      if (!CommonUtils.hasLogin()) { return };//检测是否登录
      this.setState({
         openWebsocket_group: false,//先关闭，再次链接就是新的groupCode了
      })
      makeGroupBuying({}).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;

            _this.setState({
               new100Phone: resData.new100Phone,
               expireStamp: resData.expireStamp,
               groupDiscount: null,
               groupCodeInput: resData.groupCode,
               groupCode: resData.groupCode,
               openWebsocket_group: true,//websocket
            }, _this.countPrice)
            message.success("发起拼团成功！")

         } else {
            _this.setState({
               new100Phone: [],
               expireStamp: null,
               groupDiscount: null,
               groupCodeInput: null,
               groupCode: null,
               openWebsocket_group: false,//websocket
            }, _this.countPrice)
            message.error("每天最多发起10次团购，请明天再试！")
         }
      })
   }

   //团购码输入框
   groupBuyingCodeFun = (value) => {
      console.log(value)
      let _this = this;

      value = value ? value + "" : ""
      this.setState({
         new100Phone: [],
         expireStamp: null,
         groupDiscount: null,
         groupCode: null,
         payQrCodeUrl: null,
         groupCodeInput: value,
      }, _this.countPrice)

      if (value.length == 8) {
         if (!CommonUtils.hasLogin()) {
            this.setState({
               groupCodeInput: null
            })
            return
         };//检测是否登录
         getGroupBuying({ "groupCode": value }).then(res => {
            if (res && res.data && res.data.success) {
               let resData = res.data.data;
               console.log(resData)
               var groupDiscount = null;
               switch (resData.new100Phone.length) {
                  case 2:
                     groupDiscount = 9;
                     break
                  case 3:
                     groupDiscount = 8;
                     break
                  case 4:
                     groupDiscount = 7;
                     break
                  case 5:
                     groupDiscount = 6;
                     break
                  default:
                     if (resData.new100Phone.length >= 6) {
                        groupDiscount = 5;
                     }
               }

               _this.setState({
                  new100Phone: resData.new100Phone,
                  expireStamp: resData.expireStamp,
                  groupDiscount: groupDiscount,
                  groupCode: value,
                  openWebsocket_group: true,//websocket
               }, _this.countPrice)
               message.success("拼团成功！")

            } else {
               _this.setState({
                  new100Phone: [],
                  expireStamp: null,
                  groupDiscount: null,
                  groupCode: null,
                  openWebsocket_group: false,//websocket
               }, _this.countPrice)
               message.error("团购号码已经过期，请重新发起拼团！")
            }
         });
      }

   }

   //websocket 事件
   onOpen = () => {
      console.log("onOpen")
   }

   //websocket 事件   SUCCESS == 支付成功
   onMessage = (msg) => {
      // console.log("onMessage", msg)
      try {
         msg = JSON.parse(msg)
         if (msg && msg.result && msg.result == "SUCCESS") {
            this.setState({
               payQrCodeUrl: null,//关闭支付二维码
               payTitle: "",
               showModal: true,
               showModal_payQrCode: false,
               websocket_pay: false,
            })

            //缓存权限
            CommonUtils.setLocalStorage("roleData", msg.roleData)
         }
      } catch (e) { }
   }

   //websocket ---团购参团提醒
   onMessageGroup = (msg) => {
      // console.log("onMessage", msg)
      try {
         msg = JSON.parse(msg)
         if (msg && msg.result && msg.result == "SUCCESS") {
            var groupDiscount = null;
            switch (msg.new100Phone.length) {
               case 2:
                  groupDiscount = 9;
                  break
               case 3:
                  groupDiscount = 8;
                  break
               case 4:
                  groupDiscount = 7;
                  break
               case 5:
                  groupDiscount = 6;
                  break
               default:
                  if (msg.new100Phone.length >= 6) {
                     groupDiscount = 5;
                  }
            }

            this.setState({
               new100Phone: msg.new100Phone,
               expireStamp: msg.expireStamp,
               groupDiscount: groupDiscount,
            }, this.countPrice)
            message.success("有新朋友加入组团！")
         }
      } catch (e) { }
   }

   //websocket 事件
   onClose = () => {
      console.log("onClose")
   }


   render() {
      const { shopCount, vipTime, discountCode, payablePrice, originalPrice, searchDiscountCode, finalTime,
         payQrCodeUrl, showModal, new100Phone, expireStamp, groupDiscount, groupCode, groupCodeInput,
         groupDiscountPrice, openWebsocket_group, showModal_payQrCode, websocket_pay,
         showModal_translate, showModal_translate_text, imageTranslateCount, textTranslateCount, showModal_follow_fans,
         fansCount, fansTime, payTitle, TabsActiveKey, showBuyDrawer, isGroupBuy, showModal_buygroup_info, resourcePackageCheckValue
      } = this.state;

      //判断是不是手机屏幕
      var isPhone = CommonUtils.isPhoneClient()

      const columns = [
         {
            title: '功能',
            // align: 'center',
            dataIndex: 'function',
            key: 'function',
            //   render: (text) => <a>{text}</a>,
         },
         {
            title: '免费版(永久)',
            // align: 'center',
            dataIndex: 'free',
            key: 'free',
            render: (text) => {
               if (typeof (text) == "string") {
                  return text
               } else if (text) {//true
                  return <CheckCircleOutlined style={{ fontSize: '20px', color: '#03b3b2' }} />
               } else {
                  return <CloseCircleOutlined style={{ fontSize: '20px', color: '#f56c6c' }} />
               }
            },
         },
         {
            title: '按店付费版',
            // align: 'center',
            dataIndex: 'pay',
            key: 'pay',
            render: (text) => {
               if (typeof (text) == "string") {
                  return text
               } else if (text) {//true
                  return <CheckCircleOutlined style={{ fontSize: '20px', color: '#03b3b2' }} />
               } else {
                  return <CloseCircleOutlined style={{ fontSize: '20px', color: '#f56c6c' }} />
               }
            },
         },
      ];
      var data = [
         {
            key: '1',
            function: '可授权店铺数',
            free: "2个/平台",
            pay: '按订购数量',
         },
         {
            key: '2',
            function: '图片空间',
            free: "不限",
            pay: '不限',
         },
         {
            key: '3',
            function: '产品采集',
            free: "可采10个/天",
            pay: '店铺数*3000个/月',
         },
         {
            key: '4',
            function: '产品发布',
            free: true,
            pay: true,
         },
         {
            key: '5',
            function: '图片生成视频',
            free: false,
            pay: true,
         },
         {
            key: '6',
            function: '正品、优惠、包邮图标添加',
            free: false,
            pay: true,
         },
         {
            key: '7',
            function: '图片容量',
            free: "无限(取决于您的电脑磁盘)",
            pay: "无限(取决于您的电脑磁盘)",
         },
         {
            key: '8',
            function: '图片翻译',
            free: "购买即可使用",
            pay: "购买即可使用",
         },
         {
            key: '9',
            function: '订单管理',
            free: true,
            pay: true,
         },

         {
            key: '10',
            function: '按图搜索比较最低价（开发中）',
            free: false,
            pay: true,
         },
      ];

      const options = [
         {
            label: <>关注大卖粉丝</>, value: 'fans'
         },
         {
            label: <>图片翻译</>, value: 'translateImg'
         }, //, disabled: true 
      ];

      var groupCodeUrl = window.location.origin + "/index.html#/price?gc=" + groupCode


      return (
         <React.Fragment>
            {
               websocket_pay ?
                  <ReactWebsocket
                     url={CommonUtils.baseWs + "/websocket/" + CommonUtils.getLocalStorage("token") + "/" + (new Date()).getTime() + "/0"}
                     onMessage={this.onMessage} //支付   ---  接受信息的回调
                     onOpen={this.onOpen} //websocket打开 
                     onClose={this.onClose} //websocket关闭
                     reconnect={true}
                     debug={true}
                     ref={Websocket => {
                        this.refWebSocket = Websocket;
                     }}
                  />
                  : null
            }

            {
               openWebsocket_group ?
                  <ReactWebsocket
                     url={CommonUtils.baseWs + "/websocket/" + CommonUtils.getLocalStorage("token") + "/" + (new Date()).getTime() + "/" + groupCode}
                     onMessage={this.onMessageGroup} //拼团  --- 接受信息的回调
                     onOpen={this.onOpen} //websocket打开 
                     onClose={this.onClose} //websocket关闭
                     reconnect={true}
                     debug={true}
                     ref={Websocket => {
                        this.refWebSocket_group = Websocket;
                     }}
                  />
                  : null
            }

            <Modal
               open={showModal}
               onCancel={() => this.setStateKeyValue("showModal", false)}
               footer={null}
               centered
            >
               <Result
                  status="success"
                  title="订购成功!"
                  subTitle="ERP软件需另外登录，才能生效."
                  extra={[
                     <NavLink to="/profile">
                        <Button key="buy" type="primary">前往个人中心</Button>
                     </NavLink>
                     ,
                     <Button key="console" onClick={() => this.setStateKeyValue("showModal", false)}>
                        关闭
                     </Button>
                  ]}
               />
            </Modal>


            <Modal
               title={"扫码支付-" + payTitle}
               open={showModal_payQrCode}
               onCancel={() => this.setStateKeyValue("showModal_payQrCode", false)}
               onOk={() => this.setStateKeyValue("showModal_payQrCode", false)}
               cancelText="取消"
               okText="支付完毕"
               centered
            >
               {
                  payQrCodeUrl ?
                     <div style={{ textAlign: "center" }}>
                        <div style={{ textAlign: "center", display: "inline-block" }}>
                           <QRCode value={payQrCodeUrl || '-'} errorLevel={"H"} icon={"/img/wechat_logo.png"} />
                           微信扫码支付
                        </div>
                        <div style={{ margin: "20px 0px" }}>
                           <Checkbox checked></Checkbox>
                           <span> 我已阅读并同意</span> <span className="register_xieyi">
                              <NavLink to="/agreement" target="_blank">
                                 <span  >《爆单ERP系列会员服务协议》</span>
                              </NavLink>
                           </span>
                        </div>
                     </div>
                     : <span>获取支付码失败，请重试！</span>
               }

            </Modal>


            <Modal
               title={"订购图片翻译"}
               open={showModal_translate}
               // open={true}
               onCancel={() => this.setStateKeyValue("showModal_translate", false)}
               onOk={this.buyTranslateImage}
               width={600}
               cancelText="取消"
               okText="立即订购"
               centered
            >
               <div className="messageBox">
                  <span className="messageTitle">资源包:</span>
                  <span className="messageText">图片翻译(实时)</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">功能说明:</span>
                  <span className="messageText">调用人工智能AI图片翻译模型，每翻译1张图片算为1次。该功能平台内通用。（一分钱一张,全网最便宜）</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">订购套餐:</span>
                  <div>
                     <Radio.Group value={imageTranslateCount} onChange={(e) => this.setStateKeyValue("imageTranslateCount", e.target.value)}>
                        <Radio.Button value={1000}>一千次</Radio.Button>
                        <Radio.Button value={3000}>三千次</Radio.Button>
                        <Radio.Button value={10000}>一万次</Radio.Button>
                        <Radio.Button value={30000}>三万次</Radio.Button>
                        <Radio.Button value={50000}>五万次</Radio.Button>
                        <Radio.Button value={100000}>十万次</Radio.Button>
                        <Radio.Button value={150000}>十五万次</Radio.Button>
                        <Radio.Button value={200000}>二十万次</Radio.Button>
                        <Radio.Button value={500000}>五十万次</Radio.Button>
                        <Radio.Button value={1000000}>一百万次</Radio.Button>
                        <Radio.Button value={2000000}>两百万次</Radio.Button>
                     </Radio.Group>
                  </div>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">应付金额:</span>
                  <span className="textPrice">￥{parseInt(0.01 * imageTranslateCount)} <span className="textOriginalPrice" style={{position: 'absolute'}}>￥{parseInt(0.01 * imageTranslateCount) * 4}</span></span>
               </div>

            </Modal>

            <Modal
               title={"订购文本翻译"}
               open={showModal_translate_text}
               onCancel={() => this.setStateKeyValue("showModal_translate_text", false)}
               onOk={this.buyTranslateImageText}
               width={600}
               cancelText="取消"
               okText="立即订购"
               centered
            >
               <div className="messageBox">
                  <span className="messageTitle">资源包:</span>
                  <span className="messageText">文本翻译(实时)</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">功能说明:</span>
                  <span className="messageText">调用百度翻译商业接口，翻译更准确，效率更高。字符数量的统计以翻译的源语言字符长度为标准。一个汉字、一个英文字母、一个标点符号、空格等均记为一个字符。该功能平台内通用。</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">订购套餐:</span>
                  <div>
                     <Radio.Group value={textTranslateCount} onChange={(e) => this.setStateKeyValue("textTranslateCount", e.target.value)}>
                        <Radio.Button value={1000000}>一百万次</Radio.Button>
                        <Radio.Button value={5000000}>五百万次</Radio.Button>
                        <Radio.Button value={10000000}>一千万次</Radio.Button>
                     </Radio.Group>
                  </div>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">应付金额:</span>
                  <span className="textPrice">￥{parseInt(0.00004 * textTranslateCount)}</span>
               </div>

            </Modal>

            <Modal
               title={"订购关注大卖粉丝"}
               open={showModal_follow_fans}
               // open={true}
               onCancel={() => this.setStateKeyValue("showModal_follow_fans", false)}
               onOk={this.buyfans}
               width={600}
               centered
               cancelText="取消"
               okText="立即订购"
            >
               <div className="messageBox">
                  <span className="messageTitle">资源包:</span>
                  <span className="messageText">关注大卖店铺的粉丝（只对shopee有效）</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">功能说明:</span>
                  <span className="messageText">关注大卖店铺的粉丝，当粉丝会关之后，您上新产品时，平台会将您的新品推送给粉丝，增加曝光量。同品类的店铺的粉丝，是您的精准潜在客户。</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">订购套餐:</span>
                  <div>
                     {/* <Radio.Group value={textTranslateCount} onChange={(e) => this.setStateKeyValue("textTranslateCount", e.target.value)}>
                        <Radio.Button value={1000000}>一百万次</Radio.Button>
                        <Radio.Button value={5000000}>五百万次</Radio.Button>
                        <Radio.Button value={10000000}>一千万次</Radio.Button>
                     </Radio.Group> */}


                     <div className="listLine" >
                        <span className="textTitle">订购店铺：</span>
                        <Radio.Group value={fansCount} onChange={(e) => this.setStateKeyValue("fansCount", e.target.value)}>
                           <Radio.Button value={1}>1</Radio.Button>
                           <Radio.Button value={2}>2</Radio.Button>
                           <Radio.Button value={5}>5</Radio.Button>
                           <Radio.Button value={10}>10</Radio.Button>
                           <InputNumber className="numberInputStyle" placeholder="输入店铺数" type="number"
                              onChange={(value) => this.setStateKeyValue("fansCount", value)} />
                           <Text>个店铺</Text>
                        </Radio.Group>
                        {/* <InputNumber style={{ width: '220px', marginTop: "5px" }} addonAfter="个店铺"
                           placeholder="输入店铺数" type="number" onChange={(value) => this.setStateKeyValue("fansCount", value)} /> */}

                     </div>

                     <div className="listLine">
                        <span className="textTitle">订购周期：</span>
                        <Radio.Group value={fansTime} onChange={(e) => this.setStateKeyValue("fansTime", e.target.value)}>
                           <Radio.Button value={1}>一个月</Radio.Button>
                           <Radio.Button value={6}>半年</Radio.Button>
                           <Radio.Button value={12}>一年</Radio.Button>
                           <Radio.Button value={36}>三年</Radio.Button>
                        </Radio.Group>
                     </div>


                  </div>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">应付金额:</span>
                  <span className="textPrice">￥{parseInt(5 * fansCount * fansTime)}</span>
               </div>

            </Modal>

            <Modal title="拼团信息" centered open={showModal_buygroup_info}
               onCancel={() => this.setStateKeyValue("showModal_buygroup_info", false)}
               onOk={() => this.setStateKeyValue("showModal_buygroup_info", false)}
               okText="确认"
               cancelText="取消"
            >

               {
                  groupCode ?
                     <>
                        <div className="listLine">
                           <span>团购号码：</span>{groupCode}
                           <Tooltip title={"复制"} placement="top">
                              <CopyOutlined onClick={() => CommonUtils.copyData(groupCode)} />
                           </Tooltip>
                        </div>
                        <div className="listLine">
                           <span style={{ float: 'inline-start' }}>有效期：</span>
                           <span className="priceTime"><Countdown value={(new Date().getTime() + expireStamp)} format="D 天 H 时 m 分 s 秒" /></span>
                        </div>

                        <div className="listLine">
                           <span style={{ float: 'inline-start' }}>二维码：</span>
                           <QRCode value={groupCodeUrl || '-'} errorLevel={"H"} icon={"/favicon.ico"} />
                        </div>
                        <div className="listLine">
                           <span>团购链接：</span>
                           <Text style={{ maxWidth: '280px', wordWrap: 'break-word' }} ellipsis>{groupCodeUrl} </Text>
                           <Tooltip title={"复制"} placement="top">
                              <CopyOutlined onClick={() => CommonUtils.copyData(groupCodeUrl)} />
                           </Tooltip>
                        </div>
                        <div className="listLine">
                           <span style={{ float: 'inline-start' }}>参团用户：</span>
                           <Avatar.Group
                              maxCount={6}
                              // size="large"
                              maxStyle={{
                                 color: '#f56a00',
                                 backgroundColor: '#fde3cf',
                              }}
                              style={{ verticalAlign: "middle", }}
                           >
                              {
                                 new100Phone.map(item => {
                                    return (

                                       <Tooltip title={"手机尾号：" + item} color='blue' placement="top">
                                          <Avatar style={{ backgroundColor: '#87d068' }}>
                                             {item}
                                          </Avatar>
                                       </Tooltip>


                                    )
                                 })
                              }
                           </Avatar.Group>
                        </div>


                        <div className="listLine">
                           <span>团购价：</span>
                           <span className="textdetail">
                              {groupDiscountPrice ?
                                 <span>
                                    <div style={{ display: 'inline-block' }}>
                                       <span className="textPrice">￥{groupDiscountPrice}</span>
                                       <div className="textOriginalPrice" style={{ float: 'inline-end' }}>￥{payablePrice}</div>
                                    </div>
                                    （{groupDiscount}折）
                                 </span>

                                 : "无,  将团购链接发给他人，团购的人越多，折扣越大。"}</span>
                        </div>

                     </>
                     :
                     <>
                        <div className="listLine">
                           <span>团购状态：未参团</span>
                        </div>
                        <div className="listLine">
                           <Button type="primary" onClick={this.makeGroupBuyingFun} >我发起拼团</Button>
                        </div>
                     </>


               }






            </Modal>

            <Drawer
               // title="标题"
               placement={'bottom'} //抽屉的方向
               closable={false}
               onClose={() => this.setStateKeyValue('showBuyDrawer', false)}
               open={showBuyDrawer}
               height={"78%"}
            >
               <div className="listLine">
                  <span className="textTitle">支持平台：</span>
                  <Image preview={false} className="smallIcon" src="/img/shopee_logo.png"></Image>
                  {/* <span className="textdetail">Shopee (跨境店)</span> */}
                  <span className="textdetail">Shopee</span>
                  <Image preview={false} className="smallIcon" src="/img/ozon_logo.png"></Image>
                  <span className="textdetail">Ozon</span>
               </div>
               <div className="listLine" >
                  <div className="textTitle lineHeight35">订购店铺：</div>
                  {/*  size={isPhone ? "small" : "middle"} */}
                  <Radio.Group value={shopCount} onChange={(e) => this.setShopCountFun(e.target.value)} >
                     <Radio.Button value={1}>1</Radio.Button>
                     <Radio.Button value={2}>2</Radio.Button>
                     <Radio.Button value={5}>5</Radio.Button>
                     <Radio.Button value={10}>10</Radio.Button>
                     <Radio.Button value={20}>20</Radio.Button>
                     <Radio.Button value={30}>30</Radio.Button>
                     <Radio.Button value={50}>50</Radio.Button>
                     <Radio.Button value={100}>100</Radio.Button>
                     <Radio.Button value={200}>200</Radio.Button>
                     {/* addonAfter="个店铺"  */}
                     <InputNumber className="numberInputStyle" placeholder="输入店铺数" type="number"
                        onChange={(value) => this.shopCountInputFun(value)} />
                     <Text>个店铺</Text>
                  </Radio.Group>

               </div>


               <div className="listLine">
                  <span className="textTitle lineHeight35">订购周期：</span>
                  <Radio.Group value={vipTime} onChange={(e) => this.setVipTimeFun(e.target.value)} >
                     <Radio.Button value={1}>一个月</Radio.Button>
                     <Radio.Button value={3}>一季度(8.8折)</Radio.Button>
                     <Radio.Button value={6}>半年(7.8折)</Radio.Button>
                     <Radio.Button value={12}>一年(6.8折)</Radio.Button>
                     <Radio.Button value={36}>三年(5折)</Radio.Button>
                  </Radio.Group>
               </div>

               <div className="listLine">
                  <span className="textTitle">资源包 <Popconfirm
                     title="说明"
                     description=<div><div>关注大卖粉丝：支持shopee虾皮平台，单价：1元/店/月。</div>
                        <div>图片翻译： 平台不限，单价：1元/店/500张。</div></div>
                     showCancel={false}
                     okText={"确定"}
                     icon={<InfoCircleOutlined style={{ color: 'grey', }} />}
                  >
                     <InfoCircleOutlined />
                  </Popconfirm>：</span>
                  <Checkbox.Group options={options} onChange={this.onChangeCheckbox} value={resourcePackageCheckValue} />
               </div>

               <div className="listLine">
                  <span className="textTitle lineHeight29">优惠码：</span>
                  <InputNumber className="numberInputStyle" placeholder="非必填" style={{ width: "100px" }} value={discountCode} type="number"
                     onChange={(value) => this.discountCodeFun(value)} />
                  {
                     (discountCode == null || discountCode.length == 0 || discountCode.length == 8) ?
                        null
                        :
                        <span className="textredtip">*优惠码应为8位，请核对，建议复制粘贴</span>
                  }
                  {
                     searchDiscountCode ?
                        <span style={{ lineHeight: "30px" }}>
                           {searchDiscountCode}
                           <Popconfirm
                              title="说明"
                              description=<span className="priceTime">有效期：<Countdown value={finalTime} format="D 天 H 时 m 分 s 秒" /></span>
                              showCancel={false}
                              okText={"确定"}
                              icon={<InfoCircleOutlined style={{ color: 'grey', }} />}
                           >
                              <InfoCircleOutlined />
                           </Popconfirm>
                        </span>
                        : null
                  }

               </div>

               {
                  isGroupBuy ?
                     <>
                        <div className="listLine">
                           <span className="textTitle">团购折扣：</span>
                           <div>
                              <Tag className="tagStyle" style={{ backgroundColor: '#EEF5FF', color: '#0569FF' }}>2人9折</Tag>
                              <Tag className="tagStyle" style={{ backgroundColor: '#EDFFF7', color: '#10D080' }}>3人8折</Tag>
                              <Tag className="tagStyle" style={{ backgroundColor: '#FFEFEF', color: '#FB5352' }}>4人7折</Tag>
                              <Tag className="tagStyle" style={{ backgroundColor: '#FDF6EC', color: '#FB9952' }}>5人6折</Tag>
                              <Tag className="tagStyle" style={{ backgroundColor: '#F8ECFD', color: '#CF52FB' }}>6人以上5折</Tag>
                           </div>
                        </div>
                        <div className="listLine">
                           <span className="textTitle lineHeight29">团购号码：</span>
                           <InputNumber className="numberInputStyle" style={{ width: "100px" }} value={groupCodeInput} placeholder="" type="number"
                              onChange={(value) => this.groupBuyingCodeFun(value)} />
                           <InfoCircleOutlined onClick={() => this.setStateKeyValue("showModal_buygroup_info", true)} style={{ marginRight: '5px', lineHeight: '30px' }} />
                           <Button type="primary" onClick={this.makeGroupBuyingFun}>我发起拼团</Button>
                        </div>
                     </>
                     : null
               }

               <Button type="primary" className={isPhone ? "btnDrawerBuy btnBuy" : "btnDrawerBuyComputer btnBuy"}
                  style={isPhone ? { height: "48px" } : {}}
                  onClick={() => this.shopBuyingFun(isGroupBuy)}>立即支付 ￥{isGroupBuy ? groupDiscountPrice : payablePrice}</Button>

            </Drawer>




            <div className="bodyBox">

               <Tabs
                  activeKey={TabsActiveKey}
                  type="card"
                  // size="large"
                  size={isPhone ? "small" : "large"}
                  // centered //标签居中展示
                  onTabClick={this.onTabClickFun}
                  items={[
                     {
                        label: '软件订购',
                        key: "1",
                        children: <>
                           <div className="listLine">
                              <span className="textTitle">支持平台：</span>
                              <Image preview={false} className="smallIcon" src="/img/shopee_logo.png"></Image>
                              {/* <span className="textdetail">Shopee (跨境店)</span> */}
                              <span className="textdetail">Shopee</span>
                              <Image preview={false} className="smallIcon" src="/img/ozon_logo.png"></Image>
                              <span className="textdetail">Ozon</span>
                           </div>
                           <div className="listLine" >
                              <span className="textTitle lineHeight35">订购店铺：</span>
                              {/* //size={isPhone ? "small" : "middle"} */}
                              <Radio.Group value={shopCount} onChange={(e) => this.setShopCountFun(e.target.value)} >
                                 <Radio.Button value={1}>1</Radio.Button>
                                 <Radio.Button value={2}>2</Radio.Button>
                                 <Radio.Button value={5}>5</Radio.Button>
                                 <Radio.Button value={10}>10</Radio.Button>
                                 {
                                    isPhone ? null :
                                       <>
                                          <Radio.Button value={20}>20</Radio.Button>
                                          <Radio.Button value={30}>30</Radio.Button>
                                          <Radio.Button value={50}>50</Radio.Button>
                                          <Radio.Button value={100}>100</Radio.Button>
                                          <Radio.Button value={200}>200</Radio.Button>
                                       </>
                                 }

                              </Radio.Group>
                              {
                                 isPhone ? <RightOutlined onClick={() => this.setShowBuyDrawer(true)} style={{ marginTop: '13px' }} /> :
                                    // <InputNumber style={{ width: '220px', marginTop: "5px" }} addonAfter="个店铺" placeholder="输入其他店铺个数" type="number" onChange={(value) => this.shopCountInputFun(value)} />
                                    <>
                                       <InputNumber className="numberInputStyle" placeholder="输入店铺数" type="number"
                                          onChange={(value) => this.shopCountInputFun(value)} />
                                       <Text>个店铺</Text>
                                    </>
                              }

                           </div>

                           <div className="listLine">
                              <span className="textTitle lineHeight35">订购周期：</span>
                              <Radio.Group value={vipTime} onChange={(e) => this.setVipTimeFun(e.target.value)}>
                                 {isPhone ?
                                    <>
                                       <Radio.Button value={1}>1个月</Radio.Button>
                                       <Radio.Button value={3}>1季度</Radio.Button>
                                       <Radio.Button value={12}>1年</Radio.Button>
                                    </>
                                    :
                                    <>
                                       <Radio.Button value={1}>一个月</Radio.Button>
                                       <Radio.Button value={3}>一季度(8.8折)</Radio.Button>
                                       <Radio.Button value={6}>半年(7.8折)</Radio.Button>
                                       <Radio.Button value={12}>一年(6.8折)</Radio.Button>
                                       <Radio.Button value={36}>三年(5折)</Radio.Button>
                                    </>}
                              </Radio.Group>
                              {
                                 isPhone ? <RightOutlined onClick={() => this.setShowBuyDrawer(true)} style={{ marginTop: '13px' }} /> : null
                              }
                           </div>

                           <div className="listLine">
                              <span className="textTitle">资源包<Popconfirm
                                 title="说明"
                                 description=<div><div>关注大卖粉丝：支持shopee虾皮平台，单价：1元/店/月。</div>
                                    <div>图片翻译： 平台不限，单价：1元/店/500张。</div></div>
                                 showCancel={false}
                                 okText={"确定"}
                                 icon={<InfoCircleOutlined style={{ color: 'grey', }} />}
                              >
                                 <InfoCircleOutlined />
                              </Popconfirm>：</span>
                              <Checkbox.Group options={options} onChange={this.onChangeCheckbox} value={resourcePackageCheckValue} />
                           </div>

                           <div className="listLine">
                              <span className="textTitle lineHeight29">优惠码：</span>
                              {/* <Space wrap> */}
                              <InputNumber className="numberInputStyle" placeholder="非必填" style={{ width: "100px" }} value={discountCode} type="number" onChange={(value) => this.discountCodeFun(value)} />
                              {
                                 (discountCode == null || discountCode.length == 0 || discountCode.length == 8) ?
                                    null
                                    :
                                    <span className="textredtip">*优惠码应为8位</span>
                              }
                              {
                                 searchDiscountCode ?
                                    <span style={{ lineHeight: "30px" }}>
                                       {searchDiscountCode}
                                       <Popconfirm
                                          title="说明"
                                          description=<span className="priceTime">有效期：<Countdown value={finalTime} format="D 天 H 时 m 分 s 秒" /></span>
                                          showCancel={false}
                                          okText={"确定"}
                                          icon={<InfoCircleOutlined style={{ color: 'grey', }} />}
                                       >
                                          <InfoCircleOutlined />
                                       </Popconfirm>

                                    </span>
                                    : null
                              }

                              {/* </Space> */}
                           </div>
                           <div className="listLine" style={isPhone ? { float: 'right' } : null}>
                              <div>
                                 {isPhone ? null : <span className="textTitle"></span>}
                                 <span className="textPrice">
                                    <Button className="btnBuy" type="primary" onClick={() => this.setShowBuyDrawer(false)}>单独购买 ￥{payablePrice}
                                       <span className="textOriginalPrice" >￥{originalPrice}</span>
                                    </Button>
                                    <Button className="btnBuy" type="primary" onClick={() => this.setShowBuyDrawer(true)} style={{ marginLeft: "5px" }}>免拼购买 ￥{groupDiscountPrice}</Button>
                                 </span>
                              </div>
                           </div>

                        </>,
                     },
                     {
                        label: '资源包',
                        key: "2",
                        children: <>
                           <Card hoverable className="cardItem"cover={<img alt="example" src="/img/translate-img.png"  style={{borderRadius: '0px'}}/>} bodyStyle={{padding:'0px'}} >
                              <Card.Meta title="图片翻译" description="超高性价比，降低您的成本。支持200+种语言。" style={{margin:'12px 12px 0px 12px',minHeight:'99px'}}/>
                              <div className="textPrice">￥10 <span className="textOriginalPrice" style={{position: 'absolute'}}>￥40</span></div>
                              <Button type="primary" className="cardBtn" onClick={() => this.setStateKeyValue("showModal_translate", true)}>立即购买</Button>
                           </Card>

                           <Card hoverable className="cardItem"cover={<img alt="example" src="/img/translate-text.png"  style={{borderRadius: '0px'}}/>} bodyStyle={{padding:'0px'}} >
                              <Card.Meta title="文本翻译" description="百度文本翻译。支持200+种语言。" style={{margin:'12px 12px 0px 12px',minHeight:'99px'}}/>
                              <div className="textPrice">￥40</div>
                              <Button type="primary" className="cardBtn" onClick={() => this.setStateKeyValue("showModal_translate_text", true)}>立即购买</Button>
                           </Card>

                           <Card hoverable className="cardItem"cover={<img alt="example" src="/img/follow_fans.png" style={{borderRadius: '0px'}}/>} bodyStyle={{padding:'0px'}} >
                              <Card.Meta title="关注大卖粉丝" description="粉丝回关后，您新上架的商品将会推送给粉丝。" style={{margin:'12px 12px 0px 12px',minHeight:'99px'}}/>
                              <div className="textPrice">￥5</div>
                              <Button type="primary" className="cardBtn" onClick={() => this.setStateKeyValue("showModal_follow_fans", true)}>立即购买</Button>
                           </Card>
                        </>
                     },
                     {
                        label: '功能权限',
                        key: "3",
                        children: <>
                        </>
                     },
                     {
                        label: '订购说明',
                        key: "4",
                        children: <></>
                     },

                  ]}
               />



               <br /><br /><br />
               <br /><br /><br />
               <Divider id='part-permissions'>功能权限</Divider>
               <br />
               <Table columns={columns} dataSource={data} pagination={false} />


               <br /><br /><br />
               <Divider id='part-ordering-instructions'>订购说明</Divider>
               <Text >
                  1、若您要升级店铺数，请选择大于等于此平台当前已授权店铺数加上准备授权店铺数的订购额度；<br />
                  举例：已订购了5个店铺，现在要再绑定3个店铺，那么订购数量就选择或输入8；<br />
                  升级或降级店铺数，系统都会将剩余的/1店/1天权益叠加到新的时间线中（以新的店铺数为基数算出天数，再+1天送一天）。
                  <br /><br />
                  2、若您未到期要续费，请选择与此平台最近一次订购相同的订购额度；<br />
                  举例：最近一次订购了5个店铺，现在要提前续费，那么订购数量就选择或输入5；<br /><br />
                  3、团购两种方式（团购号码有效期为1天）：<br />
                  （1）、输入他人分享的团购号码，即可参团；<br />
                  （2）、点击"我发起拼团"按钮，将团购号码、团购链接、二维码任意一个分享给其他用户，即可。<br />
                  4、单独购买和团购的购买结果是一样的，只是团购会在单独购买的基础上，再打折。<br />
                  5、优惠券或团购的最低金额为1元。<br />
               </Text>
               <br />

            </div>
         </React.Fragment>
      );
   }
}

export default Price;